import { CommContext } from '../../../../hoc/CommContext';
import NoteImg from '../../../../static/png/Note.png';
import { Information } from './data/information';
import * as React from 'react';
import styled from 'styled-components';

interface TableProps {}

const Comm2Note: React.FC<TableProps> = () => {
  const ctx = React.useContext(CommContext);
  return (
    <Note>
      <h2>あなただけが知っている情報</h2>
      <ul>
        {Information.player[ctx?.comm2.playerNum ?? 2][
          ctx?.comm2.playerId ?? 1
        ].map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
      <span>
        <img src={NoteImg} width={159} height={74} />
      </span>
    </Note>
  );
};

const Note = styled.div`
  background: #f7ffaa;
  padding: 14px 24px;
  position: relative;

  ul {
    padding: 10px 10px;
    list-style-position: inside;
  }

  li {
    font-size: 1.4rem;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
export default Comm2Note;
