export const Information = {
  common: {
    A: {
      name: '候補A：居酒屋',
      information: [
        ['いわゆる普通の居酒屋'],
        ['20人まで入れる大部屋', '掘りごたつ'],
        ['徒歩：5分'],
        ['グルメサイト評価：3.3', '肉料理がおすすめ'],
        ['コースは2種で、3,500円もしくは5,000円', 'どちらも飲み放題付き'],
        ['会社の小規模な飲み会でもたまに使用'],
      ],
    },
    B: {
      name: '候補B：レストラン',
      information: [
        ['ホテル風のおしゃれな内装が特徴のレストラン'],
        ['15人以上で貸切可'],
        ['徒歩+電車：20分', 'タクシー：10分'],
        ['グルメサイト評価：3.5', 'ビュッフェ形式'],
        ['飲み放題付き。', '品数次第で費用が変動。\n→5,000~7,000円'],
        ['備品にマイクやプロジェクターがある。'],
      ],
    },
    C: {
      name: '候補C：レンタルスペース',
      information: [
        ['多用途のカジュアルなレンタルスペース'],
        ['最大席数20人', '小さいテーブルが多い'],
        ['徒歩：10分'],
        ['飲食物持ち込み可', 'ケータリング配送対応'],
        ['スペースレンタル代は、1時間3,000円。', '人数による変動なし。'],
        ['徒歩1分にスーパー', 'プロジェクターやカラオケ　の設備あり'],
      ],
    },
    D: {
      name: '候補D：ボウリング場',
      information: [
        ['バーカウンター併設のボウリング場'],
        ['制限なし'],
        ['徒歩+電車：15分', 'タクシー：5分'],
        ['バーにて、ドリンクやおつまみ類の提供あり。'],
        ['１ゲーム:800円', 'ドリンク&おつまみは一品350円から'],
        [
          '同ビル内の飲食店で二次会が行えるセットあり',
          '費用はボーリング代込みで5,000円〜10,000円',
        ],
      ],
    },
  },
  player: {
    2: {
      1: [
        '若手の中に、会社の飲み会にはあまり参加したくない人がいるという噂がある ',
        '例年の忘年会の予算は5,000円から10,000円、年によって変動する',
        '過去の忘年会の感想「お酒を飲まないのに同じ金額を払うのに少しだけ抵抗がある」 ',
        'このチームでは、2年前まで忘年会とは別にボウリング大会が行われていた',
      ],
      2: [
        '趣味がクイズのメンバーが、クイズ大会の企画を温めているという話をしていた ',
        '家に子どもがいるため、忘年会には例年参加していない人が1人いる ',
        '忘年会用のケータリングサービスの相場は、1人あたり4,000円から7,000円程度',
        '過去の忘年会の感想「企画によっては人と話す時間が少なかったりすると残念」',
      ],
      3: [],
    },
    3: {
      1: [
        'チーム内に、食物アレルギーのある人は特に知られていない',
        '若手の中に、会社の飲み会にはあまり参加したくない人がいるという噂がある',
        '過去の忘年会の感想「お酒を飲まないのに同じ金額を払うのに少しだけ抵抗がある',
      ],
      2: [
        'このチームでは、2年前まで忘年会とは別にボウリング大会が行われていた',
        '忘年会用のケータリングサービスの相場は、1人あたり4,000円から7,000 円程度 ',
        '過去の忘年会の感想「企画によっては人と話す時間が少なかったりすると残念」',
      ],
      3: [
        '例年の忘年会の予算は5,000円から10,000円、年によって変動する ',
        '趣味がクイズのメンバーが、クイズ大会の企画を温めているという話をしていた',
        '家に子どもがいるため、忘年会には例年参加していない人が1人いる',
      ],
    },
  },
};
