export type Comm1ContextType = {
  playerNum?: 2 | 3;
  playerId?: 1 | 2 | 3;
  stage: {
    1: number[];
    2: number[];
  };
  ids: {
    1: {
      1: string;
      2: string;
      3: string;
    };
    2: {
      1: string;
      2: string;
      3: string;
    };
  };
  score: {
    1: number;
    2: number;
  };
};
export const Comm1Context: Comm1ContextType = {
  stage: {
    1: [],
    2: [],
  },
  ids: {
    1: {
      1: '',
      2: '',
      3: '',
    },
    2: {
      1: '',
      2: '',
      3: '',
    },
  },
  score: {
    1: 0,
    2: 0,
  },
};
