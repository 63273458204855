import * as React from 'react';
import styled from 'styled-components';

interface Stage2FieldProps {}

export type pair = {
  id: number;
  name: string;
};

export type tuple = {
  id: number;
  title: string;
  explanation: string;
};

export type Item = {
  questionCount: number;
  title: string;
  features: pair[];
  featuresRev: pair[];
  ideas: tuple[];
};

const Comm1Waiting: React.FC<Stage2FieldProps> = props => {
  return <Wrap></Wrap>;
};

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const DmyBtn = styled.div`
  width: 170px;
`;

const Header = styled.div`
  margin-top: 10px;
  padding: 8px 20px;
  width: calc(100% - 40px);
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

  ul {
    flex: 1;
    display: flex;
    margin: 0 20px;
    justify-content: center;

    li {
      list-style: none;
    }
  }

  button:first-child {
    svg {
      margin-right: 10px;
      margin-left: -4px;
    }
  }

  button:last-child {
    svg {
      margin-right: -4px;
      margin-left: 10px;
    }
  }
`;

interface singleStepProps {
  selected: boolean;
}

const SingleStep = styled.div`
  padding: 0 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: -8px;
  padding-bottom: 4px;
  p {
    font-size: 2rem;
  }
  span {
    font-size: 1.3rem;
  }

  ${(p: singleStepProps) => {
    return p.selected
      ? `
        color: #4F78FC;
        border-bottom: 4px solid #4F78FC;
    `
      : `
        color: #868E96;
        border-bottom: 4px solid white;
    `;
  }}
`;

const Text1 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const Text2 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 400;
`;

const Text1a = styled.a`
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const View = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
`;

const MyWrapper = styled.div`
  width: 1000px;
  height: calc(100vh - 3rem);
  display: flex;
  overflow: hidden;
`;

export default Comm1Waiting;
