import Image1 from '../../../../static/png/Comm2/place1.png';
import Image2 from '../../../../static/png/Comm2/place2.png';
import Image3 from '../../../../static/png/Comm2/place3.png';
import Image4 from '../../../../static/png/Comm2/place4.png';
import { Information } from './data/information';
import * as React from 'react';
import styled from 'styled-components';

interface TableProps {
  hideImage?: boolean;
}

const Comm2Table: React.FC<TableProps> = props => {
  return (
    <InfoTable>
      <tbody>
        <tr>
          <td></td>
          <td>
            {props.hideImage == true ? null : (
              <>
                <span>A</span>
                <img src={Image1} />
              </>
            )}
            <p>居酒屋</p>
          </td>
          <td>
            {props.hideImage == true ? null : (
              <>
                <span>B</span>
                <img src={Image2} />
              </>
            )}
            <p>レストラン</p>
          </td>
          <td>
            {props.hideImage == true ? null : (
              <>
                <span>C</span>
                <img src={Image3} />
              </>
            )}
            <p>レンタルスペース</p>
          </td>
          <td>
            {props.hideImage == true ? null : (
              <>
                <span>D</span>
                <img src={Image4} />
              </>
            )}
            <p>ボウリング場</p>
          </td>
        </tr>

        {[
          '概要',
          '人数・席',
          '会社からの\nアクセス',
          '料理',
          '費用',
          '備考',
        ].map((e, i) => (
          <tr key={i}>
            <td>{e}</td>
            <td>
              <ul>
                {Information.common.A.information[i].map((single, i) => {
                  return <li key={i}>{single}</li>;
                })}
              </ul>
            </td>
            <td>
              <ul>
                {Information.common.B.information[i].map((single, i) => {
                  return <li key={i}>{single}</li>;
                })}
              </ul>
            </td>
            <td>
              <ul>
                {Information.common.C.information[i].map((single, i) => {
                  return <li key={i}>{single}</li>;
                })}
              </ul>
            </td>
            <td>
              <ul>
                {Information.common.D.information[i].map((single, i) => {
                  return <li key={i}>{single}</li>;
                })}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </InfoTable>
  );
};

const InfoTable = styled.table`
  list-style-position: inside;
  white-space: pre-wrap;
  font-size: 1.4rem;
  table-layout: fixed;
  width: 100%;

  border-spacing: 10px 0;

  p {
    padding: 12px 20px;
  }

  tr:nth-child(2n-1) {
    td {
      background: #eaebec;
    }
  }

  tr {
    td {
      background: #f3f4f6;
      padding: 10px;
      padding-left: 18px;
      padding-right: 12px;
    }

    td:first-child {
      padding: 10px;
      color: white;
      font-weight: bold;
      text-align: center;
      font-size: 1.4rem;
      background: #7a4aff;
      width: 120px;
    }

    &:nth-child(2n) {
      td:first-child {
        background: #6f3dfc;
      }
    }
  }
  tr:first-child {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.4rem;
    position: relative;
    white-space: nowrap;
    span {
      font-size: 2.1rem;
      width: 30px;
      height: 30px;
      position: absolute;
      background: #343a40;
    }

    img {
      width: 100%;
    }

    p {
      background: transparent;
    }

    td {
      background: #343a40;
      padding: 0;
    }

    td: first-child {
      background: transparent;
    }
  }

  tr:nth-child(2) {
    td {
      li {
        list-style: none;
      }
    }
  }

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export default Comm2Table;
