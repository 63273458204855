import { deleteContext } from '../../../../hoc/CommContext';
import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const text = InstructionText[2];

const Comm2Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={() => {
          deleteContext('comm2');
          navigate(`/${trainingId}/comm2/player`);
        }}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};

export default Comm2Rule;
