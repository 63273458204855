import { CommonBG, CountDown } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface CountDownProps {}
const Comm2CountDown: React.FC<CountDownProps> = props => {
  const location = useLocation();
  const [resultSet] = React.useState<any>(location.state);
  const { trainingId } = useParams<'trainingId'>();

  return (
    <CommonBG>
      <CountDown redirect={`/${trainingId}/comm2/game`} state={resultSet} />
    </CommonBG>
  );
};

export default Comm2CountDown;
