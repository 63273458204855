import { CommContext } from '../../../../hoc/CommContext';
import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import Image1 from '../../../../static/png/Comm2/place1.png';
import Image2 from '../../../../static/png/Comm2/place2.png';
import Image3 from '../../../../static/png/Comm2/place3.png';
import Image4 from '../../../../static/png/Comm2/place4.png';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import Comm2Note from './Comm2Note';
import Comm2Table from './Comm2Table';
import { saveComm2Answer } from './api/api';
import { Comm2ContextType } from './context/Comm2Context';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CommonBG,
  FinishWindow,
  Input,
  Textarea,
  Button as _Button,
  TextWindow as _TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Stage2Props {}

const Comm2Answer: React.FC<Stage2Props> = props => {
  const duration = 10 * 60;

  const { pathname } = useLocation();
  const [finished, setFinished] = React.useState(false);
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const [remainingTime, setRemainingTime] = React.useState(999);

  React.useEffect(() => {
    setFinished(false);
  }, [pathname]);
  const ctx = React.useContext(CommContext);

  const [choosedPlace, setChoosedPlace] = React.useState<string | null>(null);
  const [choosedReason, setChoosedReason] = React.useState('');
  const [unchoosedReason, setUnchoosedReason] = React.useState('');
  const [eventContents, setEventContents] = React.useState('');
  const [memo, setMemo] = React.useState('');

  const onSend = () => {
    // wip loading
    saveComm2Answer({
      choosedPlace: choosedPlace ?? '',
      choosedReason,
      unchoosedReason,
      eventContents,
      memo,
    }).then(data => {
      ctx?.setComm2(prev => {
        prev.stage.id = data.id;
        return prev;
      });
      setFinished(true);
    });
  };

  return (
    <CommonBG>
      <MainWrapper>
        <TimeGauge
          timerKey={trainingId + '-comm2answer'}
          duration={duration}
          stageName={InstructionText[2].title}
          logo={InstructionLogo}
          color={InstructionColor}
          onSkip={() => {
            onSend();
          }}
          onRefreshDuration={(time: number) => {
            setRemainingTime(time);
          }}
          onBack={() => {
            navigate(`/${trainingId}/stages`);
          }}
        />

        <Content>
          <TWrapper>
            <TextWindow bracket>
              <p>ディスカッションの結論を各個人で入力しましょう。</p>
              <span>※この時間はチーム内での会話は禁止です。</span>
            </TextWindow>
          </TWrapper>
          <Details>
            <Summary>
              会場の候補とその情報
              <span>
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </Summary>
            <DetailContent>
              <Comm2Table hideImage />
              <Comm2Note />
            </DetailContent>
          </Details>
          <h2>決定した会場を選択してください</h2>
          <Places>
            <Place
              selected={choosedPlace == 'A'}
              disabled={choosedPlace != 'A'}
              onClick={() => {
                setChoosedPlace('A');
              }}
            >
              <span>A</span>
              <img src={Image1} />
              <p>居酒屋</p>
            </Place>
            <Place
              selected={choosedPlace == 'B'}
              disabled={choosedPlace != 'B'}
              onClick={() => {
                setChoosedPlace('B');
              }}
            >
              <span>B</span>
              <img src={Image2} />
              <p>レストラン</p>
            </Place>
            <Place
              selected={choosedPlace == 'C'}
              disabled={choosedPlace != 'C'}
              onClick={() => {
                setChoosedPlace('C');
              }}
            >
              <span>C</span>
              <img src={Image3} />
              <p>レンタルスペース</p>
            </Place>
            <Place
              selected={choosedPlace == 'D'}
              disabled={choosedPlace != 'D'}
              onClick={() => {
                setChoosedPlace('D');
              }}
            >
              <span>D</span>
              <img src={Image4} />
              <p>ボウリング場</p>
            </Place>
          </Places>

          <h2>会場を選んだ理由を入力してください</h2>
          <Textarea
            maxLength={400}
            placeholder=''
            value={choosedReason}
            onChange={(val: string) => {
              setChoosedReason(val);
            }}
          />
          <h2>他の会場を選ばなかった理由を入力してください</h2>
          <Textarea
            maxLength={400}
            placeholder=''
            value={unchoosedReason}
            onChange={(val: string) => {
              setUnchoosedReason(val);
            }}
          />
          <h2>忘年会でやることを入力してください</h2>
          <Textarea
            maxLength={400}
            placeholder=''
            value={eventContents}
            onChange={(val: string) => {
              setEventContents(val);
            }}
          />
          <h2>その他・メモ</h2>
          <Textarea
            maxLength={400}
            placeholder=''
            value={memo}
            onChange={(val: string) => {
              setMemo(val);
            }}
          />
          <ButtonWrapper>
            <Button
              color='positive'
              size='large'
              disabled={choosedPlace === null}
              onClick={() => {
                onSend();
              }}
            >
              送信
            </Button>
          </ButtonWrapper>
        </Content>
        {finished && (
          <FinishWindow
            message='実習終了です。お疲れさまでした。'
            color={InstructionColor.primary}
            onClick={() => {
              ctx?.setComm2(prev => {
                const conclusion = {
                  choosedPlace,
                  choosedReason,
                  unchoosedReason,
                  eventContents,
                  memo,
                };
                prev.stage.conclusion = conclusion;
                prev.stage.allConclusion[ctx.comm2.playerId ?? 1] = conclusion;
                return prev;
              });
              navigate(`/${trainingId}/comm2/result`);
            }}
          />
        )}
      </MainWrapper>
    </CommonBG>
  );
};

interface PlaceProps {
  selected: boolean;
  disabled: boolean;
}

const Places = styled.div`
  display: flex;
  font-weight: bold;
  text-align: center;
  color: white;
  width: 100%;
  margin: 20px -5px;
  position: relative;
`;

const Place = styled.div`
  margin: 0 5px;
  flex: 1;
  display: block;
  cursor: pointer;
  transition: 0.1s;

  p {
    font-size: 2rem;
    margin-top: 4px;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    height: auto;
    border: 4px solid transparent;
    box-sizing: border-box;
  }

  span {
    font-size: 2rem;
    position: absolute;
    width: 30px;
    height: 30px;
  }

  ${(p: PlaceProps) => {
    return p.selected
      ? `
      background: #7A4AFF;

      img {
        border: 4px solid #7A4AFF;
      }

      span {
        background: #7A4AFF;
      }
      `
      : `
      background: #343a40;
      
      span {
        background: #343a40;
      }
    `;
  }}
  ${(p: PlaceProps) => {
    return p.disabled
      ? `
      opacity:0.5;
      &:hover {
        transform: scale(1.05);
        opacity:1.0;
      }
      `
      : `
    `;
  }};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const MainWrapper = styled.div`
  width: 100%;
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;

  h2 {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }

  table {
    margin: 20px 0;
  }

  input {
    margin-top: 10px;
  }
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})``;

const TextWindow = styled(_TextWindow)`
  span {
    font-size: 2rem;
    margin: -12px 0;
    position: relative;
    display: block;
  }
`;

const Content = styled.div`
  margin: 40px auto;
  width: 100%;
  padding: 0 30px;
`;

const TWrapper = styled.div`
  > div {
    padding: 0;
  }
`;
const Summary = styled.summary`
  margin-top: 20px;
  background: white;
  font-size: 2.4rem;
  font-weight: bold;
  border: 1px solid #ced4da;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 4px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &::-webkit-details-marker {
    display: none;
  }

  span {
    transition: 0.2s;
    display: block;
    margin-right: 2px;
    margin-left: auto;
    color: #343a40;
  }
`;

const Details = styled.details`
  margin-bottom: 20px;

  &[open] summary {
    span {
      transform: rotate(-180deg);
    }
  }
`;

const DetailContent = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  animation: fadeIn 0.5s ease;
`;

export default Comm2Answer;
