import rootReducer from './reducers';
import 'react-redux';
import { compose, createStore } from 'redux';

export type RootState = ReturnType<typeof rootReducer>;
const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

declare module 'react-redux' {
  interface DefaultRootState extends RootState {}
}

export default createStore(rootReducer, composeEnhancers());
