import {
  Comm1Context,
  Comm1ContextType,
} from '../components/eventProvider/stagePages/comm1/context/Comm1Context';
import {
  Comm2Context,
  Comm2ContextType,
} from '../components/eventProvider/stagePages/comm2/context/Comm2Context';
import { previousWednesday } from 'date-fns/esm';
import * as React from 'react';

// ローカルストレージ用のキー
const KeyComm1Context = 'comm1Context';
const KeyComm2Context = 'comm2Context';

type Comm1Func = (prev: Comm1ContextType) => Comm1ContextType;
type Comm2Func = (prev: Comm2ContextType) => Comm2ContextType;
export type CommContextType = {
  comm1: Comm1ContextType;
  setComm1: (fn: Comm1Func) => void;
  comm2: Comm2ContextType;
  setComm2: (fn: Comm2Func) => void;
};

// UserContext の生成
export const CommContext = React.createContext<CommContextType | undefined>({
  comm1:
    JSON.parse(localStorage.getItem(KeyComm1Context) ?? 'null') ?? Comm1Context,
  setComm1: (fn: Comm1Func) => {
    return;
  },
  comm2:
    JSON.parse(localStorage.getItem(KeyComm2Context) ?? 'null') ?? Comm2Context,
  setComm2: (fn: Comm2Func) => {
    return;
  },
});

// UserContext にセッター関数を登録するためのコンポーネント
export const CommContextProvider: React.FC = ({ children }) => {
  const ctx: CommContextType | undefined = React.useContext(CommContext);
  const [comm1, setComm1] = React.useState(ctx?.comm1);
  const [comm2, setComm2] = React.useState(ctx?.comm2);

  if (!ctx || !comm1 || !comm2) {
    return <></>;
  }
  const newContext: CommContextType = {
    comm1,
    setComm1: (fn: Comm1Func) => {
      const _comm1 = fn(comm1);
      localStorage.setItem(KeyComm1Context, JSON.stringify(_comm1));
      setComm1(_comm1);
    },
    comm2,
    setComm2: (fn: Comm2Func) => {
      const _comm2 = fn(comm2);
      localStorage.setItem(KeyComm2Context, JSON.stringify(_comm2));
      setComm2(_comm2);
    },
  };

  return (
    <CommContext.Provider value={newContext}>{children}</CommContext.Provider>
  );
};

export const deleteContext = (gameId: string) => {
  switch (gameId) {
    case 'comm1':
      localStorage.removeItem(KeyComm1Context);
      break;
    case 'comm2':
      localStorage.removeItem(KeyComm2Context);
      break;
  }
};
