import { CommonBG, CountDown } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';

interface CountDownProps {}
const Comm1CountDown: React.FC<CountDownProps> = props => {
  const location = useLocation();
  const [resultSet] = React.useState<any>(location.state);
  const { trainingId, stageId } = useParams<'trainingId' | 'stageId'>();

  return (
    <CommonBG>
      <CountDown
        redirect={`/${trainingId}/comm1/game/${stageId}`}
        state={resultSet}
      />
    </CommonBG>
  );
};

export default Comm1CountDown;
