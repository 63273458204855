import { CommContext, CommContextType } from '../../../../hoc/CommContext';
import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import { answer, ImgPath, placeholder, question } from './data/data';
import {
  Button as _Button,
  CommonBG,
  FinishWindow,
  ScoreText,
  TextWindow as _TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Stage2Props {}

type ElementsListProps = {
  stageId: 1 | 2 | null;
  ctx: CommContextType | undefined;
  drugging: number;
  tmpAns: number[];
  setDrugging: (drugging: number) => void;
  setMousePos: (mousePos: [number, number]) => void;
};
const ElementsList: FC<ElementsListProps> = props => {
  const { stageId, drugging, ctx, tmpAns, setDrugging, setMousePos } = props;
  if (stageId == null) return null;

  const items =
    question[stageId][ctx?.comm1.playerNum ?? 2][ctx?.comm1.playerId ?? 1];
  return (
    <ElementsListWrapper
      style={{
        gridTemplateColumns:
          ctx?.comm1.playerNum == 2 ? '200px 200px 200px' : '200px 200px',
      }}
    >
      {items.map((s: ImgPath, i) => {
        const disabled = s.id == drugging || tmpAns.some(e => e === s.id);

        return (
          <SingleElement
            onMouseDown={event => {
              if (disabled == false) {
                setDrugging(i);
                const { clientX, clientY } = event;
                setMousePos([clientX, clientY]);
              }
            }}
            key={i}
            style={{
              opacity: disabled ? '0.5' : '1.0',
              transform: `translateX(${
                i < items.length / 2 ? '-50px' : '50px'
              })`,
              cursor: !disabled ? 'pointer' : '',
              boxShadow: !disabled ? '0 3px 6px rgba(0, 0, 0, 0.16)' : '',
            }}
          >
            {!disabled && <img src={s.imgPath} />}
          </SingleElement>
        );
      })}
    </ElementsListWrapper>
  );
};

type CheckListProps = {
  stageId: 1 | 2 | null;
  ctx: CommContextType | undefined;
  currentHover: number;
  tmpAns: number[];
  setDrugging: (drugging: number) => void;
  setMousePos: (mousePos: [number, number]) => void;
  sRefs: React.MutableRefObject<HTMLLIElement | null>[];
  setTmpAns: React.Dispatch<React.SetStateAction<number[]>>;
};
const CheckList: FC<CheckListProps> = props => {
  const {
    stageId,
    ctx,
    currentHover,
    tmpAns,
    setDrugging,
    setMousePos,
    sRefs,
    setTmpAns,
  } = props;

  const sHead = ['A', 'B', 'C', 'D'];

  return (
    stageId && (
      <CheckListWrapper>
        {answer[ctx?.comm1.playerNum ?? 2].map((s: ImgPath, i: number) => {
          const onMouseDown = (
            event: React.MouseEvent<HTMLLIElement, MouseEvent>
          ) => {
            if (tmpAns[s.id] >= 0) {
              setDrugging(tmpAns[s.id]);
              setTmpAns(prev => {
                prev[i] = -1;
                return prev;
              });
              const { clientX, clientY } = event;
              setMousePos([clientX, clientY]);
            }
          };
          return (
            <SingleCheckList
              key={i}
              ref={r => (sRefs[i].current = r)}
              clickable={tmpAns[s.id] != undefined && tmpAns[s.id] != -1}
              highlight={currentHover == i}
              onMouseDown={event => onMouseDown(event)}
            >
              <span>{sHead[i]}</span>
              <img
                src={
                  question[stageId][ctx?.comm1.playerNum ?? 2][
                    ctx?.comm1.playerId ?? 1
                  ][tmpAns[s.id]]?.imgPath
                }
              />
            </SingleCheckList>
          );
        })}
      </CheckListWrapper>
    )
  );
};

const Comm1Game: FC<Stage2Props> = props => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const [mousePos, setMousePos] = useState<number[]>([0, 0]);
  const [drugging, setDrugging] = useState<number>(-1);
  const [currentHover, setCurrentHover] = useState<number>(-1);

  const drugRef = useRef(-1);
  drugRef.current = drugging;

  const FieldRef0 = useRef<HTMLLIElement | null>(null);
  const FieldRef1 = useRef<HTMLLIElement | null>(null);
  const FieldRef2 = useRef<HTMLLIElement | null>(null);
  const FieldRef3 = useRef<HTMLLIElement | null>(null);

  const duration = 15 * 60;

  const { pathname } = useLocation();
  const [finished, setFinished] = useState(false);
  const { stage } = useParams();
  const stageId = stage ? (parseInt(stage) as 1 | 2) : null;
  const [remainingTime, setRemainingTime] = useState(999);

  const isHover = (obj: HTMLLIElement, x: number, y: number) => {
    const rect = obj.getBoundingClientRect();
    if (x > rect.left && y > rect.top && x < rect.right && y < rect.bottom) {
      return true;
    } else {
      return false;
    }
  };

  const sRefs = [FieldRef0, FieldRef1, FieldRef2, FieldRef3];

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (drugRef.current == -1) return;
      const { clientX, clientY } = event;
      setMousePos([clientX, clientY]);

      let onHover = -1;

      sRefs.map((single, i) => {
        if (single.current) {
          if (isHover(single.current, clientX, clientY)) {
            onHover = i;
          }
        }
      });

      if (onHover != currentHover) {
        setCurrentHover(onHover);
      }
    },
    [currentHover]
  );

  const [tmpAns, setTmpAns] = useState<number[]>([-1, -1, -1, -1]);

  const onMouseUp = useCallback(
    (event: MouseEvent) => {
      if (drugRef.current == -1) return;
      if (drugging != -1) {
        if (currentHover == -1) {
          setTmpAns(prev => {
            prev[currentHover] = -1;
            return prev;
          });
        } else {
          setTmpAns(prev => {
            prev[currentHover] = drugging;
            return prev;
          });
        }
      }
      setDrugging(-1);
      setCurrentHover(-1);
    },
    [drugging, currentHover, setDrugging, setCurrentHover, setTmpAns, drugRef]
  );

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseUp, onMouseMove]);
  useEffect(() => {
    setFinished(false);
  }, []);

  const ctx = useContext(CommContext);

  const onFinished = useCallback(() => {
    ctx?.setComm1(prev => {
      if (!prev) {
        return prev;
      }
      if (!stageId) {
        return prev;
      }
      prev.stage[stageId] = tmpAns.filter(
        (e, i) => i < placeholder[ctx?.comm1.playerNum ?? 2].length
      );
      return prev;
    });
    navigate(`/${trainingId}/comm1/result/${stageId}`);
  }, [ctx, stageId, tmpAns, navigate, trainingId]);

  const onSkip = useCallback(() => {
    if (stageId == 2) {
      navigate(`/${trainingId}/comm1/finalresult`);
    } else if (stageId == 1) {
      navigate(`/${trainingId}/comm1/countdown/${stageId + 1}`);
    }
  }, [stageId, navigate, trainingId]);
  return (
    stageId && (
      <CommonBG>
        <MainWrapper>
          <TimeGauge
            timerKey={trainingId + '-comm1game-' + stageId}
            duration={duration}
            stageName={InstructionText[1].title}
            logo={InstructionLogo}
            color={InstructionColor}
            onSkip={onSkip}
            onRefreshDuration={(time: number) => {
              setRemainingTime(time);
            }}
            onBack={() => {
              navigate(`/${trainingId}/stages`);
            }}
          />
          <Content>
            <TextWindowWrapper timeup={remainingTime <= 0}>
              <TextWindow bracket>
                写真をドラッグして選択し、Aから
                {ctx?.comm1.playerNum == 2 ? 'D' : 'C'}
                に並べる写真を全員で一致させてください。
              </TextWindow>
            </TextWindowWrapper>

            <CheckList
              stageId={stageId}
              ctx={ctx}
              currentHover={currentHover}
              tmpAns={tmpAns}
              setDrugging={setDrugging}
              setMousePos={setMousePos}
              sRefs={sRefs}
              setTmpAns={setTmpAns}
            />
            <ElementsList
              stageId={stageId}
              ctx={ctx}
              drugging={drugging}
              tmpAns={tmpAns}
              setDrugging={setDrugging}
              setMousePos={setMousePos}
            />
            <ButtonWrapper>
              <Button
                color='positive'
                size='large'
                disabled={
                  ctx?.comm1.playerNum == 2
                    ? [0, 1, 2, 3].some(
                        e => tmpAns[e] == null || tmpAns[e] == -1
                      )
                    : [0, 1, 2].some(e => tmpAns[e] == null || tmpAns[e] == -1)
                }
                onClick={() => {
                  setFinished(true);
                }}
              >
                決定
              </Button>
            </ButtonWrapper>
          </Content>

          <StageLabel>
            <Label />
            <div>
              <ScoreText value={stageId} size={60} color='#343A40' />
              <span></span>
              <ScoreText value={2} size={30} color='#343A40' />
            </div>
          </StageLabel>

          {drugging != -1 && (
            <DruggingItem
              style={{
                left: mousePos[0],
                top: mousePos[1],
              }}
            >
              <img
                src={
                  question[stageId][ctx?.comm1.playerNum ?? 2][
                    ctx?.comm1.playerId ?? 1
                  ][drugging].imgPath
                }
              />
            </DruggingItem>
          )}

          {finished && (
            <FinishWindow
              message={'次はチームメイトと写真が一致しているか確認しましょう。'}
              color={InstructionColor.primary}
              onClick={onFinished}
            />
          )}
        </MainWrapper>
      </CommonBG>
    )
  );
};

const DruggingItem = styled.div`
  pointer-events: none;

  position: fixed;
  z-index: 99;
  transform: translate(-50%, -50%);
  img {
    width: 200px;
    height: 120px;
  }
`;

const StageLabel = styled.div`
  user-select: none;
  position: absolute;
  left: 40px;
  bottom: 50px;

  > div:last-child {
    display: flex;
    align-items: baseLine;

    >ul: first-child {
      margin-bottom: -20px;
    }

    span {
      display: block;
      background: #343a40;
      width: 8px;
      height: 28px;
      transform: skew(-20deg, 0deg);
      margin: 0 10px;
      padding-bottom: 4px;
    }
  }
`;

interface TextWindowWrapperProps {
  timeup: boolean;
}

const TextWindowWrapper = styled.div`
  ${(p: TextWindowWrapperProps) =>
    p.timeup
      ? `
  @keyframes scaleAnim {
    0% {
      transform: scale(1);
    }
    80% {
      transform: scale(1);
    }
    85% {
      transform: scale(0.9);
    }
    90% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  > div {
    animation-name: scaleAnim;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
  }

  color: red;
  > div::before,
  > div::after {
    border-color: red !important;
  }

`
      : `

`}
`;

const TextWindow = styled(_TextWindow)`
  padding: 10px 20px;
  line-height: 4.8rem;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
`;
export const ElementsListWrapper = styled.ul`
  user-select: none;
  display: grid;
  column-gap: 10px;
  justify-content: center;

  background: rgba(206, 212, 218, 0.25);
  margin: 0 -50px;
  padding: 10px 0;
`;

const MainWrapper = styled.div`
  width: 100%;
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;

  img {
    pointer-events: none;
  }
`;

const Content = styled.div`
  width: calc(100% - 10rem);
  margin: 50px auto;
`;

export const SingleElement = styled.li`
  display: block;
  position: relative;
  background: #e9ecef;
  border: 1px solid #ced4da;

  width: 200px;
  height: 120px;

  margin: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

interface stateProps {
  clickable: boolean;
  highlight?: boolean;
}

export const Elements = styled.div`
  background: rgba(206, 212, 218, 0.25);
  padding: 10px;

  h2 {
    transition: 0.2s;
    margin-left: -10px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      cursor: pointer;
      margin: 6px 6px;
      border: 1px solid #ced4da;
      padding: 8px 10px;
      list-style: none;
      text-align: center;
      width: calc(50% - 34px);
      font-size: 1.6rem;
    }
  }
  margin-bottom: 20px;
`;

export const Checked: React.FC = () => {
  return (
    <svg
      id='Component_-_Complete'
      data-name='Component - Complete'
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <path
        id='パス_206'
        data-name='パス 206'
        d='M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z'
        fill='#ffa000'
      />
      <path
        id='Checkbox'
        d='M4.6,8,0,3.4l.933-.933L4.6,6.067,10.667,0,11.6.933Z'
        transform='translate(4.001 6)'
        fill='#fff'
      />
    </svg>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const CheckListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px -50px;
`;

const SingleCheckList = styled.li`
  user-select: none;
  display: block;
  list-style: none;

  width: 200px;
  height: 120px;

  background: #e9ecef;
  border: 1px solid #ced4da;

  margin: 10px 5px;
  transition: 0.2s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${(p: stateProps) => {
    return p.highlight
      ? `
        transform: scale(1.05);
    `
      : `
        transform: scale(1);
    `;
  }}

  ${(p: stateProps) => {
    return p.clickable
      ? `
      cursor:pointer;
    `
      : `
      cursor:default;
      `;
  }}

  span {
    margin-left: -1px;
    margin-top: -1px;
    font-size: 2.1rem;
    width: 30px;
    height: 30px;
    position: absolute;
    background: #343a40;
    color: white;
    text-align: center;
    font-weight: bold;
  }
`;

const Label = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='196'
      height='30'
      viewBox='0 0 196 30'
    >
      <g transform='translate(-43 -575)'>
        <g transform='translate(-7.395 574.938)'>
          <g transform='translate(50.395 0.063)'>
            <rect width='148' height='30' fill='#343a40' />
            <rect
              width='20'
              height='30'
              transform='translate(152)'
              fill='#343a40'
            />
            <rect
              width='20'
              height='30'
              transform='translate(176)'
              fill='#343a40'
            />
          </g>
        </g>
        <path
          d='M-71.96-4.78V-5.82c0-2.4-1.74-4.2-5.06-4.2h-4.44c-.36,0-.46-.2-.46-.44v-.08c0-.22.14-.38.46-.38h8.42V-16.1H-82.5c-3.58,0-5.68,1.4-5.68,5.04v.58c0,3.14,1.6,4.8,5.64,4.8h3.98c.26,0,.4.1.4.38v.06c0,.26-.1.38-.4.38h-9.3V.2h10.52C-73.44.2-71.96-2.12-71.96-4.78Zm17.56-6V-16.1H-70.42v5.32h5.1v11h6.1v-11Zm17.14,11V-9.74c0-4.96-1.4-6.66-6.68-6.66h-2.22c-5.28,0-6.42,1.7-6.42,6.66V.22h5.7V-2.24h3.96V.22Zm-5.66-7.66h-3.96v-2.3c0-1.04.16-1.34,1.06-1.34H-44c.92,0,1.08.3,1.08,1.34ZM-18.86-10c0-4.54-1.46-6.2-6.24-6.2h-3.5c-5.32,0-6.5,1.9-6.5,7v2.52c0,5.06,1.34,7,6.7,7h3.64c4.36,0,5.84-1.9,5.84-6.1V-9.36H-27.7v3.38h2.94v.38c0,.38-.24.74-.86.74h-1.8c-1.26,0-1.78-.18-1.78-1.82V-9.2c0-1.44.38-1.84,1.58-1.84h1.64c.88,0,1.18.36,1.18,1.04ZM-1.18.22V-4.9H-9.52c-1.12,0-1.28-.36-1.28-1.1h8.9V-9.84h-8.88a1.082,1.082,0,0,1,1.26-1.1h8.34V-16.1h-9.4c-5.32,0-6.2,2.88-6.2,7.02v3.1c0,3.68,1.38,6.2,6.14,6.2Z'
          transform='translate(177 597)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

export default Comm1Game;
