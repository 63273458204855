import { TrainingRouter } from './TrainingRouter';
import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import { CommContextProvider } from './hoc/CommContext';
import Store from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

export const App = () => {
  return <Router />;
};

export default App;

const Router = () => {
  return (
    <CommContextProvider>
      <Provider store={Store}>
        <BrowserRouter>
          <Routes>
            <Route path='/auth-error' element={<AuthErrorPage />} />
            <Route path='/:trainingId' element={<TrainingRouter />} />
            <Route path='/:trainingId/*' element={<TrainingRouter />} />
            <Route path='/' element={<RedirectPage />} />
            <Route path='/*' element={<Navigate to='/' />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </CommContextProvider>
  );
};
