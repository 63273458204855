import axios from 'axios';

const endpoint =
  'https://asia-northeast1-specc-dev-st-comm-4xpue.cloudfunctions.net/';
export const saveComm2Answer = async ({
  choosedPlace,
  choosedReason,
  unchoosedReason,
  eventContents,
  memo,
}: {
  choosedPlace: string;
  choosedReason: string;
  unchoosedReason: string;
  eventContents: string;
  memo: string;
}) => {
  const req = {
    choosedPlace,
    choosedReason,
    unchoosedReason,
    eventContents,
    memo,
  };
  const res = await axios.post(endpoint + 'saveComm2Answer', req);
  return res.data;
};

export const loadComm2Answer = async (id: string) => {
  const req = { id };
  const res = await axios.post(endpoint + 'loadComm2Answer', req);
  return res.data;
};
