import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const parentURI = 'https://specc-dev.riddler.co.jp';
type RedirectPageProps = Record<string, never>;
const RedirectPage: React.FC<RedirectPageProps> = () => {
  React.useEffect(() => {
    location.href = parentURI;
  }, []);

  return <CommonBG></CommonBG>;
};

export default RedirectPage;
