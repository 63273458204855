import { CommContext } from '../../../../hoc/CommContext';
import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import Image1 from '../../../../static/png/Comm2/place1.png';
import Image2 from '../../../../static/png/Comm2/place2.png';
import Image3 from '../../../../static/png/Comm2/place3.png';
import Image4 from '../../../../static/png/Comm2/place4.png';
import UserIcon from '../../../uiElements/UserIcon';
import { Images, PlayerId } from './Comm2Result';
import { CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

const Comm2FinalResult: React.FC<RuleInstrctionProps> = props => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();

  const text = InstructionText[2];
  const ctx = React.useContext(CommContext);

  const Images = { A: Image1, B: Image2, C: Image3, D: Image4 };
  const Names = {
    A: '居酒屋',
    B: 'レストラン',
    C: 'レンタルスペース',
    D: 'ボウリング場',
  };
  const key: keyof Images = ctx?.comm2.stage.conclusion.choosedPlace as any;

  if (!ctx) {
    return <></>;
  }
  const conclusion1 = ctx.comm2.stage.allConclusion[1];
  const conclusion2 = ctx.comm2.stage.allConclusion[2];
  const conclusion3 = ctx.comm2.stage.allConclusion[3];
  const key1: keyof Images = conclusion1.choosedPlace as any;
  const key2: keyof Images = conclusion2.choosedPlace as any;
  const key3: keyof Images = conclusion3.choosedPlace as any;
  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        <Wrap>
          <ResultTitle>
            <Icon>
              <UserIcon
                maxPlayers={ctx?.comm2.playerNum || 2}
                player={(ctx?.comm2.playerId ?? 1) - 1}
                width={280}
                height={248}
              />
            </Icon>
            <h2>プレイヤー{ctx?.comm2.playerId}</h2>
            <PlayerId _id={ctx?.comm2.stage.id ?? '-'} />
          </ResultTitle>

          <h2>選んだ会場</h2>

          <Places>
            <SingleResult>
              <div>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx?.comm2.playerNum || 2}
                    player={0}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h3>プレイヤー1</h3>
              </div>
              {conclusion1.choosedPlace == null ? (
                <Empty>
                  <p>未選択</p>
                </Empty>
              ) : (
                <Thumb>
                  <span>{conclusion1.choosedPlace}</span>
                  <img src={Images[key1]} />
                  <p>{Names[key1]}</p>
                </Thumb>
              )}
            </SingleResult>
            <SingleResult>
              <div>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx?.comm2.playerNum || 2}
                    player={1}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h3>プレイヤー2</h3>
              </div>
              {conclusion2.choosedPlace == null ? (
                <Empty>
                  <p>未選択</p>
                </Empty>
              ) : (
                <Thumb>
                  <span>{conclusion2.choosedPlace}</span>
                  <img src={Images[key2]} />
                  <p>{Names[key2]}</p>
                </Thumb>
              )}
            </SingleResult>

            {ctx?.comm2.playerNum === 3 && (
              <SingleResult>
                <div>
                  <Icon>
                    <UserIcon
                      maxPlayers={ctx?.comm2.playerNum || 2}
                      player={2}
                      width={280}
                      height={248}
                    />
                  </Icon>
                  <h3>プレイヤー3</h3>
                </div>
                {conclusion3.choosedPlace == null ? (
                  <Empty>
                    <p>未選択</p>
                  </Empty>
                ) : (
                  <Thumb>
                    <span>{conclusion3.choosedPlace}</span>
                    <img src={Images[key3]} />
                    <p>{Names[key3]}</p>
                  </Thumb>
                )}
              </SingleResult>
            )}
          </Places>

          <h2>会場を選んだ理由</h2>

          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={0}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー1の回答 {ctx?.comm2.playerId === 1 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>
              {conclusion1.choosedReason == ''
                ? '未入力'
                : conclusion1.choosedReason}
            </p>
          </SingleResult>
          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={1}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー2の回答 {ctx?.comm2.playerId === 2 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>
              {conclusion2.choosedReason == ''
                ? '未入力'
                : conclusion2.choosedReason}
            </p>
          </SingleResult>
          {ctx?.comm2.playerNum === 3 && (
            <SingleResult>
              <div>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx?.comm2.playerNum || 2}
                    player={2}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h3>
                  プレイヤー3の回答
                  {ctx?.comm2.playerId === 3 ? '(あなた)' : ''}
                </h3>
              </div>
              <p>
                {conclusion3.choosedReason == ''
                  ? '未入力'
                  : conclusion3.choosedReason}
              </p>
            </SingleResult>
          )}

          <h2>他の会場を選ばなかった理由</h2>

          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={0}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー1の回答 {ctx?.comm2.playerId === 1 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>
              {conclusion1.unchoosedReason == ''
                ? '未入力'
                : conclusion1.unchoosedReason}
            </p>
          </SingleResult>
          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={1}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー2の回答 {ctx?.comm2.playerId === 2 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>
              {conclusion2.unchoosedReason == ''
                ? '未入力'
                : conclusion2.unchoosedReason}
            </p>
          </SingleResult>

          {ctx?.comm2.playerNum === 3 && (
            <SingleResult>
              <div>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx?.comm2.playerNum || 2}
                    player={2}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h3>
                  プレイヤー3の回答
                  {ctx?.comm2.playerId === 3 ? '(あなた)' : ''}
                </h3>
              </div>
              <p>
                {conclusion3.unchoosedReason == ''
                  ? '未入力'
                  : conclusion3.unchoosedReason}
              </p>
            </SingleResult>
          )}

          <h2>忘年会でやること</h2>

          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={0}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー1の回答 {ctx?.comm2.playerId === 1 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>
              {conclusion1.eventContents == ''
                ? '未入力'
                : conclusion1.eventContents}
            </p>
          </SingleResult>
          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={1}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー2の回答 {ctx?.comm2.playerId === 2 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>
              {conclusion2.eventContents == ''
                ? '未入力'
                : conclusion2.eventContents}
            </p>
          </SingleResult>
          {ctx?.comm2.playerNum === 3 && (
            <SingleResult>
              <div>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx?.comm2.playerNum || 2}
                    player={2}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h3>
                  プレイヤー3の回答
                  {ctx?.comm2.playerId === 3 ? '(あなた)' : ''}
                </h3>
              </div>
              <p>
                {conclusion3.eventContents == ''
                  ? '未入力'
                  : conclusion3.eventContents}
              </p>
            </SingleResult>
          )}

          <h2>その他・メモ</h2>

          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={0}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー1の回答 {ctx?.comm2.playerId === 1 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>{conclusion1.memo == '' ? '未入力' : conclusion1.memo}</p>
          </SingleResult>
          <SingleResult>
            <div>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm2.playerNum || 2}
                  player={1}
                  width={280}
                  height={248}
                />
              </Icon>
              <h3>
                プレイヤー2の回答 {ctx?.comm2.playerId === 2 ? '(あなた)' : ''}
              </h3>
            </div>
            <p>{conclusion2.memo == '' ? '未入力' : conclusion2.memo}</p>
          </SingleResult>
          {ctx?.comm2.playerNum === 3 && (
            <SingleResult>
              <div>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx?.comm2.playerNum || 2}
                    player={2}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h3>
                  プレイヤー3の回答
                  {ctx?.comm2.playerId === 3 ? '(あなた)' : ''}
                </h3>
              </div>
              <p>{conclusion3.memo == '' ? '未入力' : conclusion3.memo}</p>
            </SingleResult>
          )}
        </Wrap>
      </Result>
    </CommonBG>
  );
};

const Places = styled.div`
  display: flex;
  margin: 0 -10px;

  > div {
    flex: 1;
    margin: 0 10px;
  }
`;

const SingleResult = styled.div`
  > div:first-child {
    position: relative;
    align-items: center;
    background: #7a4aff;
    color: white;
    padding: 10px 20px;

    h3 {
      margin: 0;
      margin-left: 70px;
      flex: 1;
      font-size: 2rem;
      font-weight: bold;
    }

    span {
      display: flex;
      align-items: center;

      svg {
        font-size: 2rem;
        margin-left: 10px;
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  p {
    font-size: 1.8rem;
    font-weight: bold;
    background: rgba(206, 212, 218, 0.25);
    padding: 20px 30px;
    margin-bottom: 20px;
    white-space: pre-wrap;
  }
`;

const Wrap = styled.div`
  h2 {
    font-size: 2.8rem;
    margin: 30px 0;
    margin-bottom: 40px;
  }
`;

const ResultTitle = styled.div`
  margin-top: 40px;
  display: flex;
  position: relative;
  align-items: center;
  background: #7a4aff;
  color: white;
  padding: 10px 20px;

  h2 {
    margin: 0;
    margin-left: 70px;
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.8rem;
    font-weight: bold;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-left: 10px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

const Icon = styled.div`
  overflow: hidden;
  border-radius: 99px;
  width: 60px;
  height: 60px;
  position: absolute;
  border: 4px solid #7a4aff;
  margin-top: -32px;
  margin-left: -10px;

  img {
    width: 122px;
    height: auto;
    margin-left: -31px;
    margin-top: -24px;
  }
`;

const Thumb = styled.div`
  img {
    width: 100%;
    height: auto;
    border: 4px solid #7a4aff;
    box-sizing: border-box;
  }

  span {
    position: absolute;
    background: #7a4aff;
    font-weight: bold;
    color: white;
    padding: 4px 10px;
    font-size: 2rem;
  }

  p {
    text-align: center;
    background: #7a4aff;
    color: white;
    font-weight: bold;
    margin: 0;
    margin-top: -8px;
    padding: 6px;
  }
`;

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eaebec;

  p {
    background: transparent;
    text-align: center;
    margin: 0;
  }
`;

export default Comm2FinalResult;
