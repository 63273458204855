import { CommContext } from '../../../../hoc/CommContext';
import Image1 from '../../../../static/png/Comm2/place1.png';
import Image2 from '../../../../static/png/Comm2/place2.png';
import Image3 from '../../../../static/png/Comm2/place3.png';
import Image4 from '../../../../static/png/Comm2/place4.png';
import UserIcon from '../../../uiElements/UserIcon';
import { loadComm2Answer } from './api/api';
import { Comm2ContextType, Conclusion } from './context/Comm2Context';
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CommonBG,
  Input,
  TextWindow,
  Button as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

export interface Images {
  A: string;
  B: string;
  C: string;
  D: string;
}

export const PlayerId = ({ _id }: { _id: string }) => {
  const [copied, setCopied] = React.useState(false);
  return (
    <span>
      <p>プレイヤーID {_id}</p>
      <FontAwesomeIcon
        onClick={() => {
          navigator.clipboard.writeText(_id).then(
            () => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 3000);
              // console.log('Async: Copying to clipboard was successful!');
            },
            err => {
              // console.error('Async: Could not copy text: ', err);
            }
          );
        }}
        icon={faCopy}
      />
      {copied && <Copied>コピーしました</Copied>}
    </span>
  );
};

interface ResultContentProps {
  player: number;
}

const ResultContent: React.FC<ResultContentProps> = props => {
  const ctx = React.useContext(CommContext);
  const [id, setId] = React.useState('');
  const [open, setOpen] = React.useState(ctx?.comm2.playerId === props.player);
  const [err, setErr] = React.useState(false);
  const key: keyof Comm2ContextType['stage']['allConclusion'] =
    props.player as keyof Comm2ContextType['stage']['allConclusion'];
  const [loading, setLoading] = React.useState<boolean>(false);

  const RemoveButton = (props: any) => {
    return (
      <SubButton onClick={props.onClick}>
        <FontAwesomeIcon icon={faTimes} />
        <p>再入力する</p>
      </SubButton>
    );
  };

  const ResultHeader = () => {
    return (
      <ResultTitle>
        <Icon>
          <UserIcon
            maxPlayers={ctx?.comm2.playerNum || 2}
            player={props.player - 1}
            width={280}
            height={248}
          />
        </Icon>
        <h2>
          プレイヤー{props.player}の回答{' '}
          {ctx?.comm2.playerId === props.player ? '(あなた)' : ''}
        </h2>
        {ctx?.comm2.playerId === props.player ? (
          <PlayerId _id={ctx?.comm2.stage.id} />
        ) : null}
        {ctx?.comm2.playerId !== props.player && open ? (
          <RemoveButton
            onClick={() => {
              setOpen(false);
            }}
          />
        ) : null}
      </ResultTitle>
    );
  };

  if (open && ctx != null) {
    return (
      <SingleResult>
        <ResultHeader />
        <SingleContent>
          <ResultText>
            <p>回答済み</p>
          </ResultText>
        </SingleContent>
      </SingleResult>
    );
  }

  return (
    <SingleResult>
      <ResultHeader />
      <SingleContent>
        <InputField loading={loading}>
          {loading ? (
            <p>送信中</p>
          ) : err ? (
            <p style={{ color: 'red' }}>IDが間違っています。</p>
          ) : (
            <p>プレイヤー{props.player}のIDを入力してください。</p>
          )}
          <Input
            fullWidth
            placeholder='ABCDEFGH'
            value={id}
            onChange={async (val: string) => {
              setId(val);
            }}
          />
          <Button
            color='positive'
            size='medium'
            disabled={id == ''}
            onClick={async () => {
              if (id !== '') {
                setLoading(true);
                const result = await loadComm2Answer(id);
                setLoading(false);
                if (result) {
                  ctx?.setComm2((prev: any) => {
                    prev.stage.allConclusion[props.player] = result;
                    return prev;
                  });
                  setOpen(true);
                } else {
                  // 存在しないID
                  setErr(true);
                }
              }
            }}
          >
            追加
          </Button>
        </InputField>
      </SingleContent>
    </SingleResult>
  );
};

const Comm2Result: React.FC<RuleInstrctionProps> = props => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const location = useLocation();
  const [resultSet] = useState<any>(location.state);
  const ctx = React.useContext(CommContext);

  return (
    <CommonBG>
      <Content>
        <TextWrap>
          <TextWindow bracket>
            グループ内でプレイヤーIDを共有して回答を比較しましょう
          </TextWindow>
        </TextWrap>
        <ResultContent player={1} />
        <ResultContent player={2} />
        {ctx?.comm2.playerNum === 3 && <ResultContent player={3} />}
        <ButtonWrapper>
          <Button
            color='positive'
            size='large'
            onClick={() => {
              navigate(`/${trainingId}/comm2/finalresult`);
            }}
          >
            回答を比較する
          </Button>
        </ButtonWrapper>
      </Content>
    </CommonBG>
  );
};

const Icon = styled.div`
  overflow: hidden;
  border-radius: 99px;
  width: 60px;
  height: 60px;
  position: absolute;
  border: 4px solid #7a4aff;

  img {
    width: 122px;
    height: auto;
    margin-left: -31px;
    margin-top: -24px;
  }
`;

const ResultText = styled.div`
  height: 136px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2.6rem !important;
    font-weight: bold;
  }
`;

const Copied = styled.div`
  background: white;
  color: #343a40;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 4px 6px;
  position: absolute;
  right: 10px;
  bottom: 40px;
  border: 1px solid #868e96;
`;

const ResultTitle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background: #7a4aff;
  color: white;
  padding: 10px 20px;
  z-index: 3;

  h2 {
    margin-left: 80px;
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.8rem;
    font-weight: bold;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-left: 10px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

const TextWrap = styled.div`
  whitespace: 'nowrap';
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

const Content = styled.div`
  margin: 50px auto;
  padding: 0 40px;

  p {
    font-size: 1.6rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  > button:first-child {
    margin-right: 20px;
  }
`;

const SingleResult = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SingleContent = styled.div`
  background: rgba(206, 212, 218, 0.25);
  position: relative;
`;

interface InputFieldProps {
  loading: boolean;
}

const SubButton = styled.div`
  font-weight: bold;
  padding: 4px 6px;
  cursor: pointer;
  transition: 0.2s;

  display: flex;
  fustify-contents: center;
  align-items: center;

  p {
    margin-left: 8px;
    font-size: 1.4rem;
  }

  svg {
    font-size: 2rem;
  }

  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const InputField = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    margin-bottom: 0;
    margin-top: 7px;
    width: 260px;
  }

  input {
  }

  button {
    margin-left: 16px;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  transition: 0.2s;

  ${(p: InputFieldProps) => {
    return p.loading
      ? `
      opacity: 0.6;
      pointer-events: none;
    `
      : ``;
  }};
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})``;

export default Comm2Result;
