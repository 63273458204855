import { CommContext } from '../../../../hoc/CommContext';
import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import UserIcon from '../../../uiElements/UserIcon';
import { PlayerId } from '../comm2/Comm2Result';
import { AnswerImages, ScoreRate } from './Comm1Result';
import { calcUnchoosedId, calcWrong } from './context/Comm1Util';
import { question } from './data/data';
import { CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

const Comm1FinalResult: React.FC<RuleInstrctionProps> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const [resultSet] = useState<any>(location.state);
  const { trainingId } = useParams<'trainingId'>();

  const text = InstructionText[1];
  const ctx = React.useContext(CommContext);

  const myIds = [
    ctx?.comm1.ids[1][ctx?.comm1.playerId ?? 1] ?? '',
    ctx?.comm1.ids[2][ctx?.comm1.playerId ?? 1] ?? '',
  ];

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        <Wrap>
          <ResultTitle>
            <Icon>
              <UserIcon
                maxPlayers={ctx?.comm1.playerNum || 2}
                player={(ctx?.comm1.playerId || 1) - 1}
                width={280}
                height={248}
              />
            </Icon>
            <h2>プレイヤー{ctx?.comm1.playerId}</h2>
          </ResultTitle>

          {([1, 2] as (1 | 2)[]).map((stageId: 1 | 2, i: number) => {
            const PlayerIdText = () => {
              return (
                <PlayerIdWrapper>
                  <PlayerId _id={myIds[i]} />
                </PlayerIdWrapper>
              );
            };

            const id1 = ctx?.comm1.ids[stageId][1] ?? '';
            const id2 = ctx?.comm1.ids[stageId][2] ?? '';
            const id3 = ctx?.comm1.ids[stageId][3] ?? '';
            const unchoosedId1 = calcUnchoosedId(id1, ctx?.comm1);
            const unchoosedId2 = calcUnchoosedId(id2, ctx?.comm1);
            const unchoosedId3 = calcUnchoosedId(id3, ctx?.comm1);

            const [wrong1, wrong2, wrong3] = calcWrong(
              id1,
              id2,
              id3,
              stageId,
              ctx?.comm1
            );

            return (
              <SingleStage key={i}>
                <PageTitle>ステージ{stageId}</PageTitle>

                <SingleResult>
                  <div>
                    <Icon>
                      <UserIcon
                        maxPlayers={ctx?.comm1.playerNum || 2}
                        player={0}
                        width={280}
                        height={248}
                      />
                    </Icon>
                    <h3>
                      プレイヤー1の回答
                      {ctx?.comm1.playerId === 1 ? '(あなた)' : ''}
                    </h3>
                    {ctx?.comm1.playerId === 1 && <PlayerIdText />}
                  </div>
                  <div>
                    <AnswerImages
                      stage={ctx?.comm1.stage[stageId]}
                      answer={question[stageId][ctx?.comm1.playerNum ?? 2][1]}
                      choosed={ctx?.comm1.ids[stageId][1].split('')}
                      unchoosed={unchoosedId1.split('')}
                      wrong={wrong1}
                    />
                  </div>
                </SingleResult>

                <SingleResult>
                  <div>
                    <Icon>
                      <UserIcon
                        maxPlayers={ctx?.comm1.playerNum || 2}
                        player={1}
                        width={280}
                        height={248}
                      />
                    </Icon>
                    <h3>
                      プレイヤー2の回答
                      {ctx?.comm1.playerId === 2 ? '(あなた)' : ''}
                    </h3>
                    {ctx?.comm1.playerId === 2 && <PlayerIdText />}
                  </div>
                  <div>
                    <AnswerImages
                      stage={ctx?.comm1.stage[stageId]}
                      answer={question[stageId][ctx?.comm1.playerNum ?? 2][2]}
                      choosed={ctx?.comm1.ids[stageId][2].split('')}
                      unchoosed={unchoosedId2.split('')}
                      wrong={wrong2}
                    />
                  </div>
                </SingleResult>

                {ctx?.comm1.playerNum === 3 && (
                  <SingleResult>
                    <div>
                      <Icon>
                        <UserIcon
                          maxPlayers={ctx?.comm1.playerNum || 2}
                          player={2}
                          width={280}
                          height={248}
                        />
                      </Icon>
                      <h3>
                        プレイヤー3の回答
                        {ctx?.comm1.playerId === 3 ? '(あなた)' : ''}
                      </h3>
                      {ctx?.comm1.playerId === 3 && <PlayerIdText />}
                    </div>
                    <div>
                      <AnswerImages
                        stage={ctx?.comm1.stage[stageId]}
                        answer={question[stageId][ctx?.comm1.playerNum ?? 2][3]}
                        choosed={ctx?.comm1.ids[stageId][3].split('')}
                        unchoosed={unchoosedId3.split('')}
                        wrong={wrong3}
                      />
                    </div>
                  </SingleResult>
                )}

                <ScoreRate value={ctx?.comm1.score[stageId] ?? 0} />
              </SingleStage>
            );
          })}
        </Wrap>
      </Result>
    </CommonBG>
  );
};

const SingleStage = styled.div``;

const PlayerIdWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 14px;
  p {
    font-size: 1.4rem;
  }
`;

const SingleResult = styled.div`
  margin: 30px 0;

  > div:first-child {
    position: relative;
    align-items: center;
    background: #7a4aff;
    color: white;
    padding: 10px 20px;

    h3 {
      margin: 0;
      margin-left: 70px;
      flex: 1;
      font-size: 2rem;
      font-weight: bold;
    }

    span {
      display: flex;
      align-items: center;

      svg {
        font-size: 2rem;
        margin-left: 10px;
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  > div:last-child {
    background: rgba(206, 212, 218, 0.25);
  }
`;

const Icon = styled.div`
  overflow: hidden;
  border-radius: 99px;
  width: 60px;
  height: 60px;
  position: absolute;
  border: 4px solid #7a4aff;
  margin-top: -32px;
  margin-left: -10px;

  img {
    width: 122px;
    height: auto;
    margin-left: -31px;
    margin-top: -24px;
  }
`;
const Wrap = styled.div`
  h2 {
    font-size: 2.8rem;
    margin: 30px 0;
    margin-bottom: 40px;
  }
`;

const ResultTitle = styled.div`
  margin-top: 40px;
  display: flex;
  position: relative;
  align-items: center;
  background: #7a4aff;
  color: white;
  padding: 10px 20px;

  h2 {
    margin: 0;
    margin-left: 70px;
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.8rem;
    font-weight: bold;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-left: 10px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

const PageTitle = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 2px solid #343a40;
  padding: 10px 0;
`;

interface DetailProps {
  open: boolean;
}

export default Comm1FinalResult;
