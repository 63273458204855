export type Conclusion = {
  choosedPlace: string | null;
  choosedReason: string;
  unchoosedReason: string;
  eventContents: string;
  memo: string;
};
export type Comm2ContextType = {
  playerNum?: 2 | 3;
  playerId?: 1 | 2 | 3;
  stage: {
    conclusion: Conclusion;
    id: string;
    allConclusion: {
      1: Conclusion;
      2: Conclusion;
      3: Conclusion;
    };
  };
};
export const Comm2Context: Comm2ContextType = {
  stage: {
    conclusion: {
      choosedPlace: null,
      choosedReason: '',
      unchoosedReason: '',
      eventContents: '',
      memo: '',
    },
    id: '',
    allConclusion: {
      1: {
        choosedPlace: null,
        choosedReason: '',
        unchoosedReason: '',
        eventContents: '',
        memo: '',
      },
      2: {
        choosedPlace: null,
        choosedReason: '',
        unchoosedReason: '',
        eventContents: '',
        memo: '',
      },
      3: {
        choosedPlace: null,
        choosedReason: '',
        unchoosedReason: '',
        eventContents: '',
        memo: '',
      },
    },
  },
};
