import { app } from '.';
import FinishPage from './components/eventProvider/common/FinishPage';
import Loading from './components/eventProvider/common/Loading';
import SelectStage from './components/eventProvider/common/SelectStage';
import StartPage from './components/eventProvider/common/StartPage';
import Comm1CountDown from './components/eventProvider/stagePages/comm1/Comm1CountDown';
import Comm1FinalResult from './components/eventProvider/stagePages/comm1/Comm1FinalResult';
import Comm1Game from './components/eventProvider/stagePages/comm1/Comm1Game';
import Comm1PlayerSelector from './components/eventProvider/stagePages/comm1/Comm1PlayerSelector';
import Comm1Result from './components/eventProvider/stagePages/comm1/Comm1Result';
import Comm1Rule from './components/eventProvider/stagePages/comm1/Comm1Rule';
import Comm1Waiting from './components/eventProvider/stagePages/comm1/Comm1Waiting';
import Comm2Answer from './components/eventProvider/stagePages/comm2/Comm2Answer';
import Comm2CountDown from './components/eventProvider/stagePages/comm2/Comm2CountDown';
import Comm2FinalResult from './components/eventProvider/stagePages/comm2/Comm2FinalResult';
import Comm2Game from './components/eventProvider/stagePages/comm2/Comm2Game';
import Comm2PlayerSelector from './components/eventProvider/stagePages/comm2/Comm2PlayerSelector';
import Comm2Result from './components/eventProvider/stagePages/comm2/Comm2Result';
import Comm2Rule from './components/eventProvider/stagePages/comm2/Comm2Rule';
import Comm2Waiting from './components/eventProvider/stagePages/comm2/Comm2Waiting';
import { useSignIn } from '@riddler-co-jp/specc-auth-client';
import React, { FC } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useParams,
} from 'react-router-dom';

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

export const TrainingRouter = () => {
  const { trainingId } = useParams<'trainingId'>();
  const match = useMatch('/:trainingId/*');
  const [signedIn, signInLoading] = useSignIn(
    app,
    'communicate',
    trainingId,
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );
  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    console.log(signInLoading, signedIn);
    return [
      {
        condition: !signInLoading && signedIn,
        redirectPathOnFail: '/auth-error',
      },
    ];
  }, [signInLoading, signedIn]);

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue =
      'このページを再読み込みしますか？入力した内容は保存されません。';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  const onExit = (url: string) => {
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    window.location.href = url;
  };

  if (signInLoading) {
    return <Loading />;
  }

  const page = match?.pathname.split('/') ?? [];
  if (['comm1', 'comm2'].includes(page[2])) {
    const currentPage = page.slice(3).join('/');
    if (currentPage !== '' && !currentPage.match(/introduction/)) {
      localStorage.setItem('currentpage-' + page[2], currentPage);
    }
  }

  return (
    <Routes>
      {/* communicate1 */}
      <Route
        path='/comm1/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm1/countdown/:stageId'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1CountDown />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm1/player'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1PlayerSelector />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm1/waiting'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1Waiting />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm1/game/:stage'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1Game />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm1/result/:stage'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm1/finalresult'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm1FinalResult />
          </ConditionedRoute>
        }
      />
      {/* communicate2 */}
      <Route
        path='/comm2/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/player'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2PlayerSelector />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/waiting'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2Waiting />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/countdown'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2CountDown />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/game'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2Game />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/answer'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2Answer />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/comm2/finalresult'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Comm2FinalResult />
          </ConditionedRoute>
        }
      />
      <Route
        path='/'
        element={
          signInLoading ? (
            <Loading />
          ) : (
            <ConditionedRoute rules={onlyValidUserRules}>
              <StartPage />
            </ConditionedRoute>
          )
        }
      />
      <Route
        path='/stages'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <SelectStage />
          </ConditionedRoute>
        }
      />
      <Route
        path='/finish'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <FinishPage onExit={onExit} />
          </ConditionedRoute>
        }
      />
    </Routes>
  );
};
