import { CommContext } from '../../../../hoc/CommContext';
import UserIcon from '../../../uiElements/UserIcon';
import { PlayerId } from '../comm2/Comm2Result';
import { calcUnchoosedId, calcWrong } from './context/Comm1Util';
import {
  ImgPath,
  checker,
  idValidator,
  placeholder,
  question,
} from './data/data';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CommonBG,
  Input,
  ScoreText,
  TextWindow,
  Button as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

export const ScoreRate: React.FC<{ value: number }> = props => {
  return (
    <Rate>
      <PerLabel />
      <span>
        <ScoreText value={props.value} size={60} color='#343A40' />
        <Per />
      </span>
    </Rate>
  );
};

interface AnswerImagesProps {
  choosed: string[] | undefined;
  unchoosed: string[] | undefined;
  answer: ImgPath[];
  stage: number[] | undefined;
  wrong: boolean[];
}
export const AnswerImages: React.FC<AnswerImagesProps> = props => {
  if (!props.choosed) {
    return <></>;
  }
  if (!props.stage) {
    return <></>;
  }
  const sHead = ['A', 'B', 'C', 'D'];
  const value = props.choosed.map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));
  const unChoosedValue = props.unchoosed?.map(
    e => e.charCodeAt(0) - 'A'.charCodeAt(0)
  );

  return (
    <Images>
      {props.stage.map((e: any, i: number) => {
        return (
          <SingleImage key={e}>
            <span>{sHead[i]}</span>
            <img src={props.answer[value[i]]?.imgPath} />
            {props.wrong[i] && (
              <WrongImage>
                <WrongIcon />
              </WrongImage>
            )}
          </SingleImage>
        );
      })}
      {props.unchoosed != undefined && unChoosedValue != undefined && (
        <UnchoosedImages>
          <p>選ばなかった画像</p>
          <ul>
            {props.stage.map((e: any, i: number) => {
              if (unChoosedValue[i] == undefined) return null;
              return (
                <SingleImage key={e}>
                  <img src={props.answer[unChoosedValue[i]]?.imgPath} />
                </SingleImage>
              );
            })}
          </ul>
        </UnchoosedImages>
      )}
    </Images>
  );
};

const WrongIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='67.07'
      height='67.07'
      viewBox='0 0 67.07 67.07'
    >
      <path
        id='合体_24'
        data-name='合体 24'
        d='M-13944.463-15517.534l26.465,26.464,26.465-26.464,7.07,7.07-26.464,26.465,26.464,26.465-7.07,7.07-26.465-26.464-26.465,26.464-7.07-7.07,26.464-26.465-26.464-26.465Z'
        transform='translate(13951.533 15517.534)'
        fill='#fff'
        opacity='0.6'
      />
    </svg>
  );
};

const WrongImage = styled.div`
  top: 0;
  height: 96%;
  width: 100%;
  background: rgba(255, 0, 0, 0.3);
  border: solid 1px rgba(255, 0, 0, 1);
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnchoosedImages = styled.li`
  display: flex;
  position: relative;
  p {
    position: absolute;
    margin-left: 10px;
    margin-top: 6px;
    font-weight: bold;
    white-space: nowrap;
    font-size: 1.6rem;
  }
  ul {
    display: flex;
    height: 100%;
    align-items: end;
  }
  img {
    height: 96px !important;
    width: auto !important;
  }
`;

const Comm1Result: React.FC<RuleInstrctionProps> = props => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const location = useLocation();
  const [resultSet] = useState<any>(location.state);
  const { stage } = useParams();
  const stageId = stage ? (parseInt(stage) as 1 | 2) : null;
  const ctx = React.useContext(CommContext);

  const [id1, setId1] = React.useState('');
  const [id2, setId2] = React.useState('');
  const [id3, setId3] = React.useState('');
  const [open1, setOpen1] = React.useState(ctx?.comm1.playerId === 1);
  const [open2, setOpen2] = React.useState(ctx?.comm1.playerId === 2);
  const [open3, setOpen3] = React.useState(ctx?.comm1.playerId === 3);
  const [score, setScore] = React.useState(0);
  const [err1, setErr1] = React.useState(false);
  const [err2, setErr2] = React.useState(false);
  const [err3, setErr3] = React.useState(false);

  console.log(ctx);
  const [myId] = React.useState(
    (ctx?.comm1.stage?.[stageId ?? 1] ?? [])
      .filter((e, i) => i < placeholder[ctx?.comm1.playerNum ?? 2].length)
      .map(e =>
        String.fromCharCode(
          'A'.charCodeAt(0) +
            question[stageId ?? 1][ctx?.comm1.playerNum ?? 2][
              ctx?.comm1.playerId ?? 1
            ][e].id
        )
      )
      .join('')
  );

  const RemoveButton = (props: any) => {
    return (
      <SubButton onClick={props.onClick}>
        <FontAwesomeIcon icon={faTimes} />
        <p>再入力する</p>
      </SubButton>
    );
  };

  // 選ばなかった画像のID
  const unchoosedId1 = React.useMemo(() => {
    return calcUnchoosedId(id1, ctx?.comm1);
  }, [ctx?.comm1, id1]);
  const unchoosedId2 = React.useMemo(() => {
    return calcUnchoosedId(id2, ctx?.comm1);
  }, [ctx?.comm1, id2]);
  const unchoosedId3 = React.useMemo(() => {
    return calcUnchoosedId(id3, ctx?.comm1);
  }, [ctx?.comm1, id3]);

  React.useEffect(() => {
    if (stageId === null) {
      return;
    }
    if (ctx?.comm1.playerId === 1) {
      setId1(myId);
      ctx?.setComm1(prev => {
        prev.ids[stageId][1] = myId;
        return prev;
      });
    } else if (ctx?.comm1.playerId === 2) {
      setId2(myId);
      ctx?.setComm1(prev => {
        prev.ids[stageId][2] = myId;
        return prev;
      });
    } else if (ctx?.comm1.playerId === 3) {
      setId3(myId);
      ctx?.setComm1(prev => {
        prev.ids[stageId][3] = myId;
        return prev;
      });
    }
  }, [ctx, myId, stageId]);

  React.useEffect(() => {
    if (
      ctx?.comm1.playerId !== 1 &&
      id1.length !== placeholder[ctx?.comm1.playerNum ?? 2].length
    ) {
      return;
    }
    if (
      ctx?.comm1.playerId !== 2 &&
      id2.length !== placeholder[ctx?.comm1.playerNum ?? 2].length
    ) {
      return;
    }
    if (
      ctx?.comm1.playerNum === 3 &&
      ctx.comm1.playerId !== 3 &&
      id3.length !== placeholder[ctx.comm1.playerNum].length
    ) {
      return;
    }
    if (!stageId) {
      return;
    }
    const playerNum = ctx?.comm1.playerNum;
    if (!playerNum) {
      return;
    }
    if (!open1) {
      return;
    }
    if (!open2) {
      return;
    }
    if (ctx?.comm1.playerNum === 3 && !open3) {
      return;
    }

    const idValue1 = id1
      .split('')
      .map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));
    const idValue2 = id2
      .split('')
      .map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));
    if (playerNum === 2) {
      const correctCount = idValue1.filter((e, i) => {
        const correct =
          checker[stageId][playerNum][
            question[stageId][playerNum][1][e].imgId
          ] ===
            checker[stageId][playerNum][
              question[stageId][playerNum][2][idValue2[i]].imgId
            ] &&
          checker[stageId][playerNum][
            question[stageId][playerNum][1][e].imgId
          ] !== -1;
        return correct;
      }).length;
      const _score = Math.round((correctCount / idValue1.length) * 100);
      setScore(_score);
      ctx.setComm1(prev => {
        prev.score[stageId] = _score;
        return prev;
      });
    } else if (playerNum === 3) {
      const idValue3 = id3
        .split('')
        .map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));
      const correctCount = idValue1
        .map((e, i) => {
          const checker1 =
            checker[stageId][playerNum][
              question[stageId][playerNum][1][e].imgId
            ];
          const checker2 =
            checker[stageId][playerNum][
              question[stageId][playerNum][2][idValue2[i]].imgId
            ];
          const checker3 =
            checker[stageId][playerNum][
              question[stageId][playerNum][3][idValue3[i]].imgId
            ];
          const correct12 = checker1 === checker2 && checker1 !== -1;
          const correct23 = checker2 === checker3 && checker2 !== -1;
          const correct31 = checker3 === checker1 && checker3 !== -1;
          const score = [0, 2, 2, 3];
          return score[[correct12, correct23, correct31].filter(e => e).length];
        })
        .reduce((a, b) => a + b);
      const _score = Math.round((correctCount / (idValue1.length * 3)) * 100);
      setScore(_score);
      ctx.setComm1(prev => {
        prev.score[stageId] = _score;
        return prev;
      });
    }
  }, [id1, id2, id3, stageId, ctx, open1, open2, open3]);

  const [wrong1, wrong2, wrong3] = React.useMemo(() => {
    if (ctx?.comm1.playerId !== 1 && !open1) {
      return [
        [false, false, false, false],
        [false, false, false, false],
        [false, false, false, false],
      ];
    }
    if (ctx?.comm1.playerId !== 2 && !open2) {
      return [
        [false, false, false, false],
        [false, false, false, false],
        [false, false, false, false],
      ];
    }
    if (ctx?.comm1.playerNum === 3 && ctx.comm1.playerId !== 3 && !open3) {
      return [
        [false, false, false, false],
        [false, false, false, false],
        [false, false, false, false],
      ];
    }
    return calcWrong(id1, id2, id3, stageId, ctx?.comm1);
  }, [id1, id2, id3, stageId, ctx, open1, open2, open3]);

  return (
    stageId && (
      <CommonBG>
        <Content>
          <TextWrap>
            <TextWindow bracket>
              グループ内でプレイヤーIDを共有して回答を比較しましょう
            </TextWindow>
          </TextWrap>

          <SingleResult>
            <ResultTitle>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm1.playerNum || 2}
                  player={0}
                  width={280}
                  height={248}
                />
              </Icon>
              <h2>
                プレイヤー1の回答 {ctx?.comm1.playerId === 1 ? '(あなた)' : ''}
              </h2>
              {ctx?.comm1.playerId === 1 ? <PlayerId _id={myId} /> : null}
              {ctx?.comm1.playerId !== 1 && open1 ? (
                <RemoveButton
                  onClick={() => {
                    setOpen1(false);
                  }}
                />
              ) : null}
            </ResultTitle>
            <SingleContent>
              {open1 ? (
                <AnswerImages
                  stage={ctx?.comm1.stage[stageId]}
                  answer={question[stageId][ctx?.comm1.playerNum ?? 2][1]}
                  choosed={id1.split('')}
                  unchoosed={unchoosedId1.split('')}
                  wrong={wrong1}
                />
              ) : (
                <InputField>
                  {err1 ? (
                    <p style={{ color: 'red' }}>IDが間違っています。</p>
                  ) : (
                    <p>プレイヤー1のIDを入力してください</p>
                  )}
                  <Input
                    fullWidth
                    placeholder={placeholder[ctx?.comm1.playerNum ?? 2]}
                    value={id1}
                    onChange={(val: string) => {
                      setId1(val);
                    }}
                  />
                  <Button
                    color='positive'
                    size='medium'
                    disabled={id1 == ''}
                    onClick={() => {
                      if (
                        idValidator[ctx?.comm1.playerNum ?? 2].test(id1) &&
                        !/([A-F]).*\1/.test(id1)
                      ) {
                        ctx?.setComm1(prev => {
                          prev.ids[stageId][1] = id1;
                          return prev;
                        });
                        setOpen1(true);
                        setErr1(false);
                      } else {
                        // 不正なid
                        setErr1(true);
                      }
                    }}
                  >
                    追加
                  </Button>
                </InputField>
              )}
            </SingleContent>
          </SingleResult>
          <SingleResult>
            <ResultTitle>
              <Icon>
                <UserIcon
                  maxPlayers={ctx?.comm1.playerNum || 2}
                  player={1}
                  width={280}
                  height={248}
                />
              </Icon>
              <h2>
                プレイヤー2の回答 {ctx?.comm1.playerId === 2 ? '(あなた)' : ''}
              </h2>
              {ctx?.comm1.playerId === 2 ? <PlayerId _id={myId} /> : null}
              {ctx?.comm1.playerId !== 2 && open2 ? (
                <RemoveButton
                  onClick={() => {
                    setOpen2(false);
                  }}
                />
              ) : null}
            </ResultTitle>
            <SingleContent>
              {open2 ? (
                <AnswerImages
                  stage={ctx?.comm1.stage[stageId]}
                  answer={question[stageId][ctx?.comm1.playerNum ?? 2][2]}
                  choosed={id2.split('')}
                  unchoosed={unchoosedId2.split('')}
                  wrong={wrong2}
                />
              ) : (
                <InputField>
                  {err2 ? (
                    <p style={{ color: 'red' }}>IDが間違っています。</p>
                  ) : (
                    <p>プレイヤー2のIDを入力してください</p>
                  )}
                  <Input
                    fullWidth
                    placeholder={placeholder[ctx?.comm1.playerNum ?? 2]}
                    value={id2}
                    onChange={(val: string) => {
                      setId2(val);
                    }}
                  />
                  <Button
                    color='positive'
                    size='medium'
                    disabled={id2 == ''}
                    onClick={() => {
                      if (
                        idValidator[ctx?.comm1.playerNum ?? 2].test(id2) &&
                        !/([A-F]).*\1/.test(id2)
                      ) {
                        ctx?.setComm1(prev => {
                          prev.ids[stageId][2] = id2;
                          return prev;
                        });
                        setOpen2(true);
                        setErr2(false);
                      } else {
                        // 不正なid
                        setErr2(true);
                      }
                    }}
                  >
                    追加
                  </Button>
                </InputField>
              )}
            </SingleContent>
          </SingleResult>
          {ctx?.comm1.playerNum === 3 && (
            <SingleResult>
              <ResultTitle>
                <Icon>
                  <UserIcon
                    maxPlayers={ctx.comm1.playerNum || 2}
                    player={2}
                    width={280}
                    height={248}
                  />
                </Icon>
                <h2>
                  プレイヤー3の回答 {ctx.comm1.playerId === 3 ? '(あなた)' : ''}
                </h2>
                {ctx.comm1.playerId === 3 ? <PlayerId _id={myId} /> : null}
                {ctx.comm1.playerId !== 3 && open3 ? (
                  <RemoveButton
                    onClick={() => {
                      setOpen3(false);
                    }}
                  />
                ) : null}
              </ResultTitle>
              <SingleContent>
                {ctx.comm1.playerId === 3 || open3 ? (
                  <AnswerImages
                    stage={ctx.comm1.stage[stageId]}
                    answer={question[stageId][ctx.comm1.playerNum][3]}
                    choosed={id3.split('')}
                    unchoosed={unchoosedId3.split('')}
                    wrong={wrong3}
                  />
                ) : (
                  <InputField>
                    {err3 ? (
                      <p style={{ color: 'red' }}>IDが間違っています。</p>
                    ) : (
                      <p>プレイヤー3のIDを入力してください</p>
                    )}
                    <Input
                      fullWidth
                      placeholder={placeholder[ctx.comm1.playerNum]}
                      value={id3}
                      onChange={(val: string) => {
                        setId3(val);
                      }}
                    />
                    <Button
                      color='positive'
                      size='medium'
                      disabled={id3 == ''}
                      onClick={() => {
                        if (
                          idValidator[ctx.comm1.playerNum ?? 2].test(id3) &&
                          !/([A-F]).*\1/.test(id3)
                        ) {
                          ctx.setComm1(prev => {
                            prev.ids[stageId][3] = id3;
                            return prev;
                          });
                          setOpen3(true);
                          setErr3(false);
                        } else {
                          // 不正なid
                          setErr3(true);
                        }
                      }}
                    >
                      追加
                    </Button>
                  </InputField>
                )}
              </SingleContent>
            </SingleResult>
          )}
          <ButtonWrapper>
            <ScoreRate value={score} />
            {stageId === 1 && (
              <Button
                color='positive'
                size='large'
                disabled={
                  !open1 || !open2 || (ctx?.comm1.playerNum === 3 && !open3)
                }
                onClick={() => {
                  navigate(`/${trainingId}/comm1/countdown/2`);
                }}
              >
                次のお題へ
              </Button>
            )}
            {stageId === 2 && (
              <Button
                color='positive'
                size='large'
                disabled={
                  !open1 || !open2 || (ctx?.comm1.playerNum === 3 && !open3)
                }
                onClick={() => {
                  navigate(`/${trainingId}/comm1/finalresult`);
                }}
              >
                結果を見る
              </Button>
            )}
          </ButtonWrapper>
        </Content>
      </CommonBG>
    )
  );
};

const Images = styled.ul`
  display: flex;
  overflow-x: scroll;
`;

export const SingleImage = styled.li`
  position: relative;
  list-style: none;
  margin: 10px 5px;

  img {
    width: 200px;
    height: 120px;
  }

  &:first-child {
    margin-left: 10px;
  }
  &:last-child {
    margin-right: 10px;
  }

  span {
    font-size: 2.1rem;
    width: 30px;
    height: 30px;
    position: absolute;
    background: #343a40;
    color: white;
    text-align: center;
    font-weight: bold;
  }
`;

const Icon = styled.div`
  overflow: hidden;
  border-radius: 99px;
  width: 60px;
  height: 60px;
  position: absolute;
  border: 4px solid #7a4aff;

  img {
    width: 122px;
    height: auto;
    margin-left: -31px;
    margin-top: -24px;
  }
`;

const Rate = styled.div`
  flex: 1;

  > svg {
    width: 180px;
    height: 28px;
  }

  span {
    display: flex;
    align-items: baseline;
    display: flex;

    > svg {
      margin-left: 6px;
      padding-bottom: 6px;
    }
  }
`;

const ResultTitle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background: #7a4aff;
  color: white;
  padding: 10px 20px;

  h2 {
    margin-left: 80px;
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.8rem;
    font-weight: bold;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      font-size: 2rem;
      margin-left: 10px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;

const TextWrap = styled.div`
  whitespace: 'nowrap';
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

const Content = styled.div`
  margin: 50px auto;
  padding: 0 40px;

  p {
    font-size: 1.6rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  button {
    margin-right: 20px;
  }

  button:last-child {
    margin-right: 0px;
  }
`;

const SingleResult = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SingleContent = styled.div`
  background: rgba(206, 212, 218, 0.25);
`;

const InputField = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    margin-bottom: 0;
    margin-top: 7px;
    width: 260px;
  }

  input {
  }

  button {
    margin-left: 16px;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})``;

const SubButton = styled.div`
  font-weight: bold;
  padding: 4px 6px;
  cursor: pointer;
  transition: 0.2s;

  display: flex;
  fustify-contents: center;
  align-items: center;

  p {
    margin-left: 8px;
    font-size: 1.4rem;
  }

  svg {
    font-size: 2rem;
  }

  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const Per = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='39.184'
      height='35.91'
      viewBox='0 0 39.184 35.91'
    >
      <path
        id='パス_2437'
        data-name='パス 2437'
        d='M40.446-14.028A8.123,8.123,0,0,0,39.06-20.37,5.642,5.642,0,0,0,34.524-22.3H30.492a9.272,9.272,0,0,0-3.15.378l6.8-13.566h-9.03L20.58-26.376q.567-3.717-1.134-5.67c-1.134-1.3-3.024-1.974-5.5-1.974H10.332c-4.452,0-7.224,2.226-7.854,6.51l-.966,6.468A7.8,7.8,0,0,0,2.9-14.784,5.519,5.519,0,0,0,7.392-12.81h4.032a10.713,10.713,0,0,0,2.394-.168L7.056.42h9.072l5.88-11.676L21.42-9.072c-.42,2.6-.042,4.662,1.092,5.964,1.176,1.344,2.982,2.058,5.5,2.058h3.57c4.452,0,7.266-2.268,7.9-6.51ZM32.76-13.1c-.084.294-.462,2.436-.5,2.688-.126.5-.294.672-1.134.672h-.5a1.419,1.419,0,0,1-.882-.168.944.944,0,0,1-.126-.756,15.889,15.889,0,0,0,.42-1.932c.21-.882.588-1.134,1.134-1.134h.8a.993.993,0,0,1,.672.168A.6.6,0,0,1,32.76-13.1ZM12.306-24.444c-.042.042-.378,1.932-.378,1.932-.168.882-.63,1.134-1.176,1.134H10a.913.913,0,0,1-.672-.21c-.126-.084-.126-.294-.126-.462.042-.252.462-2.394.5-2.6.084-.546.294-.672,1.092-.672h.546a1.867,1.867,0,0,1,.84.126C12.306-25.074,12.348-24.906,12.306-24.444Z'
        transform='translate(-1.384 35.49)'
        fill='#343a40'
      />
    </svg>
  );
};

const PerLabel = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='257'
      height='40'
      viewBox='0 0 257 40'
    >
      <g
        id='グループ_4024'
        data-name='グループ 4024'
        transform='translate(29.605 -0.063)'
      >
        <g
          id='グループ_1675'
          data-name='グループ 1675'
          transform='translate(0 0)'
        >
          <g
            id='グループ_1873'
            data-name='グループ 1873'
            transform='translate(0)'
          >
            <rect
              id='長方形_1146'
              data-name='長方形 1146'
              width='203'
              height='40'
              transform='translate(-29.605 0.063)'
              fill='#343a40'
            />
            <rect
              id='長方形_1147'
              data-name='長方形 1147'
              width='20'
              height='40'
              transform='translate(180.395 0.063)'
              fill='#343a40'
            />
            <rect
              id='長方形_1309'
              data-name='長方形 1309'
              width='20'
              height='40'
              transform='translate(207.395 0.063)'
              fill='#343a40'
            />
            <path
              id='パス_2436'
              data-name='パス 2436'
              d='M-61.05-7.05V-17.16H-89.01V-7.05ZM-31.02,2.7V-6.12a10.56,10.56,0,0,1-2.64-.48,30.713,30.713,0,0,0,1.95-9.42h.69V-24.3h-6.09v-1.08h-7.32a27.235,27.235,0,0,1-.69,6.81v-6.57H-59.01v7.29h1.5l-.33,1.05h-.72v5.67h2.91v.51h-2.91v5.7h2.91v.51h-3.36v6.9h13.74v-6.9h-3.27v-.51h3.27V-8.37c.27-.12.54-.24.81-.39a17.52,17.52,0,0,0,.84,2.4A7.3,7.3,0,0,1-44.79-6V2.7A12.335,12.335,0,0,0-38.43.12,15.071,15.071,0,0,0-31.02,2.7Zm-6.66-18.72a19.951,19.951,0,0,1-.9,5.16,7.549,7.549,0,0,1-.3-1.89h-1.47a14.217,14.217,0,0,0,1.47-3.27Zm-7.65-1.83c-.21.54-.42.84-.6.84v2.52l-.78-3.36Zm-5.76.93a9.384,9.384,0,0,1-1.32.12l.27-1.05h.81Zm5.16,6.3h-3.15v-1.17a1.835,1.835,0,0,0,.36-.15l.18.87h2.61ZM-1.05,1.29V-5.82h-.48v-5.52l-3.96-.57,3.96-.57v-5.34l-6.15,1.05v7.02l-.72-4.26h-2.58l3.12-2.79h-4.65l1.77-1.02h9.69v-6.93h-9.96v-.72h-8.1v.72h-9.9v6.93h9.21l-2.01,1.41v3.87h1.26l-1.26,1.11v4.89h2.7v.72h-6.42a10.087,10.087,0,0,0,3.21-1.02v-9.93l-6.18-1.05v5.34l3.57.51a18.53,18.53,0,0,1-3.54.39v5.76h-.54V1.29h9.9V2.58h8.1V1.29ZM-1.8-5.82h-9.21v-.54h3.9l-.09-.6Zm-10.65-6.3a3.7,3.7,0,0,1-1.2.24h-.51l1.53-1.32Zm.66,5.58h-6.36a20.361,20.361,0,0,0,6.24-.75Z'
              transform='translate(164.395 32.063)'
              fill='#fff'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Comm1Result;
