import { timeActions } from '../../../redux/actions/timeActions';
import { useTimer } from '../../../redux/selectors/timeSelectors';
import {
  InstructionColors,
  TimeGaugeView,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

interface TimeGaugeProps {
  duration: number;
  color?: InstructionColors;
  timerKey?: string;
  stageName: string;
  logo: ReactNode;
  onSkip?: () => void;
  onBack: () => void;
  onFinished?: () => void;
  onRefreshDuration?: (time: number) => void;
}

const TimeGauge: React.FC<TimeGaugeProps> = props => {
  const dispatch = useDispatch();
  const [finished, setFinished] = React.useState(false);
  const {
    remainingTime: nullableRemainingTime,
    duration,
    noGaugeTransition,
  } = useTimer();

  React.useEffect(() => {
    const duration =
      window.localStorage.getItem('timer:' + (props.timerKey ?? 'general')) ??
      '' + props.duration;
    window.localStorage.setItem(
      'timer:' + (props.timerKey ?? 'general'),
      duration
    );
    dispatch(
      timeActions.setTimer({
        duration: parseInt(duration),
      })
    );
  }, [dispatch, props.duration]);

  const remainingTime = nullableRemainingTime ?? duration;

  React.useEffect(() => {
    if (remainingTime <= 0 && finished == false) {
      setFinished(true);
      if (props.onFinished) props.onFinished();
    }
    if (finished && remainingTime > 0) {
      setFinished(false);
    }

    if (props.onRefreshDuration && nullableRemainingTime != null) {
      props.onRefreshDuration(nullableRemainingTime);
    }
    window.localStorage.setItem(
      'timer:' + (props.timerKey ?? 'general'),
      remainingTime.toString()
    );
  }, [finished, props, remainingTime, nullableRemainingTime]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      dispatch(timeActions.updateRemainingTime());
    }, 250);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [dispatch]);

  return (
    <>
      {/*<Prompt message='Are you sure you want to leave?' />*/}
      <TimeGaugeView
        remainingTime={remainingTime}
        duration={duration}
        noTransition={noGaugeTransition}
        stageName={props.stageName}
        color={props.color}
        logo={props.logo}
        onSkip={props.onSkip}
        onBack={props.onBack}
      />
    </>
  );
};

export default TimeGauge;
