import Stage1_2_1_1 from '../../../../../static/png/Comm1/stage1/2/1/Commu5-1_2_stage1_1.jpg';
import Stage1_2_1_2 from '../../../../../static/png/Comm1/stage1/2/1/Commu5-1_2_stage1_2.jpg';
import Stage1_2_1_3 from '../../../../../static/png/Comm1/stage1/2/1/Commu5-1_2_stage1_3.jpg';
import Stage1_2_1_4 from '../../../../../static/png/Comm1/stage1/2/1/Commu5-1_2_stage1_4.jpg';
import Stage1_2_1_5 from '../../../../../static/png/Comm1/stage1/2/1/Commu5-1_2_stage1_5.jpg';
import Stage1_2_1_6 from '../../../../../static/png/Comm1/stage1/2/1/Commu5-1_2_stage1_6.jpg';
import Stage1_2_2_7 from '../../../../../static/png/Comm1/stage1/2/2/Commu5-1_2_stage1_7.jpg';
import Stage1_2_2_8 from '../../../../../static/png/Comm1/stage1/2/2/Commu5-1_2_stage1_8.jpg';
import Stage1_2_2_9 from '../../../../../static/png/Comm1/stage1/2/2/Commu5-1_2_stage1_9.jpg';
import Stage1_2_2_10 from '../../../../../static/png/Comm1/stage1/2/2/Commu5-1_2_stage1_10.jpg';
import Stage1_2_2_11 from '../../../../../static/png/Comm1/stage1/2/2/Commu5-1_2_stage1_11.jpg';
import Stage1_2_2_12 from '../../../../../static/png/Comm1/stage1/2/2/Commu5-1_2_stage1_12.jpg';
import Stage1_3_1_1 from '../../../../../static/png/Comm1/stage1/3/1/Commu5-1_3_stage1_1.jpg';
import Stage1_3_1_2 from '../../../../../static/png/Comm1/stage1/3/1/Commu5-1_3_stage1_2.jpg';
import Stage1_3_1_3 from '../../../../../static/png/Comm1/stage1/3/1/Commu5-1_3_stage1_3.jpg';
import Stage1_3_1_4 from '../../../../../static/png/Comm1/stage1/3/1/Commu5-1_3_stage1_4.jpg';
import Stage1_3_2_5 from '../../../../../static/png/Comm1/stage1/3/2/Commu5-1_3_stage1_5.jpg';
import Stage1_3_2_6 from '../../../../../static/png/Comm1/stage1/3/2/Commu5-1_3_stage1_6.jpg';
import Stage1_3_2_7 from '../../../../../static/png/Comm1/stage1/3/2/Commu5-1_3_stage1_7.jpg';
import Stage1_3_2_8 from '../../../../../static/png/Comm1/stage1/3/2/Commu5-1_3_stage1_8.jpg';
import Stage1_3_3_9 from '../../../../../static/png/Comm1/stage1/3/3/Commu5-1_3_stage1_9.jpg';
import Stage1_3_3_10 from '../../../../../static/png/Comm1/stage1/3/3/Commu5-1_3_stage1_10.jpg';
import Stage1_3_3_11 from '../../../../../static/png/Comm1/stage1/3/3/Commu5-1_3_stage1_11.jpg';
import Stage1_3_3_12 from '../../../../../static/png/Comm1/stage1/3/3/Commu5-1_3_stage1_12.jpg';
import Stage2_2_1_1 from '../../../../../static/png/Comm1/stage2/2/1/Commu5-1_2_stage2_1.jpg';
import Stage2_2_1_2 from '../../../../../static/png/Comm1/stage2/2/1/Commu5-1_2_stage2_2.png';
import Stage2_2_1_3 from '../../../../../static/png/Comm1/stage2/2/1/Commu5-1_2_stage2_3.png';
import Stage2_2_1_4 from '../../../../../static/png/Comm1/stage2/2/1/Commu5-1_2_stage2_4.png';
import Stage2_2_1_5 from '../../../../../static/png/Comm1/stage2/2/1/Commu5-1_2_stage2_5.png';
import Stage2_2_1_6 from '../../../../../static/png/Comm1/stage2/2/1/Commu5-1_2_stage2_6.png';
import Stage2_2_2_7 from '../../../../../static/png/Comm1/stage2/2/2/Commu5-1_2_stage2_7.png';
import Stage2_2_2_8 from '../../../../../static/png/Comm1/stage2/2/2/Commu5-1_2_stage2_8.png';
import Stage2_2_2_9 from '../../../../../static/png/Comm1/stage2/2/2/Commu5-1_2_stage2_9.png';
import Stage2_2_2_10 from '../../../../../static/png/Comm1/stage2/2/2/Commu5-1_2_stage2_10.png';
import Stage2_2_2_11 from '../../../../../static/png/Comm1/stage2/2/2/Commu5-1_2_stage2_11.png';
import Stage2_2_2_12 from '../../../../../static/png/Comm1/stage2/2/2/Commu5-1_2_stage2_12.png';
import Stage2_3_1_1 from '../../../../../static/png/Comm1/stage2/3/1/Commu5-1_3_stage2_1.png';
import Stage2_3_1_2 from '../../../../../static/png/Comm1/stage2/3/1/Commu5-1_3_stage2_2.png';
import Stage2_3_1_3 from '../../../../../static/png/Comm1/stage2/3/1/Commu5-1_3_stage2_3.png';
import Stage2_3_1_4 from '../../../../../static/png/Comm1/stage2/3/1/Commu5-1_3_stage2_4.png';
import Stage2_3_2_5 from '../../../../../static/png/Comm1/stage2/3/2/Commu5-1_3_stage2_5.png';
import Stage2_3_2_6 from '../../../../../static/png/Comm1/stage2/3/2/Commu5-1_3_stage2_6.png';
import Stage2_3_2_7 from '../../../../../static/png/Comm1/stage2/3/2/Commu5-1_3_stage2_7.png';
import Stage2_3_2_8 from '../../../../../static/png/Comm1/stage2/3/2/Commu5-1_3_stage2_8.jpg';
import Stage2_3_3_9 from '../../../../../static/png/Comm1/stage2/3/3/Commu5-1_3_stage2_9.png';
import Stage2_3_3_10 from '../../../../../static/png/Comm1/stage2/3/3/Commu5-1_3_stage2_10.png';
import Stage2_3_3_11 from '../../../../../static/png/Comm1/stage2/3/3/Commu5-1_3_stage2_11.png';
import Stage2_3_3_12 from '../../../../../static/png/Comm1/stage2/3/3/Commu5-1_3_stage2_12.png';

export type ImgPath = {
  id: number;
  name: string;
  imgPath: string;
  imgId: number;
};

export const placeholder = {
  2: 'ABCD',
  3: 'ABC',
};

export const allIds = {
  2: 'ABCDEF',
  3: 'ABCD',
};

export const idValidator = {
  2: /^[A-F]{4}$/,
  3: /^[A-D]{3}$/,
};

export const checker: {
  1: {
    2: {
      [key: number]: number;
    };
    3: {
      [key: number]: number;
    };
  };
  2: {
    2: {
      [key: number]: number;
    };
    3: {
      [key: number]: number;
    };
  };
} = {
  1: {
    2: {
      1: -1,
      2: 2,
      3: -1,
      4: 4,
      5: 5,
      6: 6,
      7: 6,
      8: -1,
      9: 4,
      10: 2,
      11: 5,
      12: -1,
    },
    3: {
      1: -1,
      2: 2,
      3: 3,
      4: 4,
      5: 3,
      6: 4,
      7: -1,
      8: 2,
      9: 4,
      10: 3,
      11: -1,
      12: 2,
    },
  },
  2: {
    2: {
      1: -1,
      2: 2,
      3: 3,
      4: -1,
      5: 5,
      6: 6,
      7: 6,
      8: -1,
      9: 5,
      10: 3,
      11: 2,
    },
    3: {
      1: 1,
      2: 2,
      3: -1,
      4: 4,
      5: 4,
      6: 1,
      7: 2,
      8: -1,
      9: -1,
      10: 2,
      11: 4,
      12: 1,
    },
  },
};
export const answer: {
  2: ImgPath[];
  3: ImgPath[];
} = {
  2: [
    {
      id: 0,
      name: 'A',
      imgPath: '',
      imgId: 0,
    },
    {
      id: 1,
      name: 'B',
      imgPath: '',
      imgId: 0,
    },
    {
      id: 2,
      name: 'C',
      imgPath: '',
      imgId: 0,
    },
    {
      id: 3,
      name: 'D',
      imgPath: '',
      imgId: 0,
    },
  ],
  3: [
    {
      id: 0,
      name: 'A',
      imgPath: '',
      imgId: 0,
    },
    {
      id: 1,
      name: 'B',
      imgPath: '',
      imgId: 0,
    },
    {
      id: 2,
      name: 'C',
      imgPath: '',
      imgId: 0,
    },
  ],
};
export const question: {
  1: {
    2: {
      1: ImgPath[];
      2: ImgPath[];
      3: ImgPath[];
    };
    3: {
      1: ImgPath[];
      2: ImgPath[];
      3: ImgPath[];
    };
  };
  2: {
    2: {
      1: ImgPath[];
      2: ImgPath[];
      3: ImgPath[];
    };
    3: {
      1: ImgPath[];
      2: ImgPath[];
      3: ImgPath[];
    };
  };
} = {
  1: {
    2: {
      1: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage1_2_1_1,
          imgId: 1,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage1_2_1_2,
          imgId: 2,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage1_2_1_3,
          imgId: 3,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage1_2_1_4,
          imgId: 4,
        },
        {
          id: 4,
          name: '画像5',
          imgPath: Stage1_2_1_5,
          imgId: 5,
        },
        {
          id: 5,
          name: '画像6',
          imgPath: Stage1_2_1_6,
          imgId: 6,
        },
      ],
      2: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage1_2_2_7,
          imgId: 7,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage1_2_2_8,
          imgId: 8,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage1_2_2_9,
          imgId: 9,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage1_2_2_10,
          imgId: 10,
        },
        {
          id: 4,
          name: '画像5',
          imgPath: Stage1_2_2_11,
          imgId: 11,
        },
        {
          id: 5,
          name: '画像6',
          imgPath: Stage1_2_2_12,
          imgId: 12,
        },
      ],
      3: [],
    },
    3: {
      /* プレイヤーID */
      1: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage1_3_1_1,
          imgId: 1,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage1_3_1_2,
          imgId: 2,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage1_3_1_3,
          imgId: 3,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage1_3_1_4,
          imgId: 4,
        },
      ],
      2: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage1_3_2_5,
          imgId: 5,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage1_3_2_6,
          imgId: 6,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage1_3_2_7,
          imgId: 7,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage1_3_2_8,
          imgId: 8,
        },
      ],
      3: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage1_3_3_9,
          imgId: 9,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage1_3_3_10,
          imgId: 10,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage1_3_3_11,
          imgId: 11,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage1_3_3_12,
          imgId: 12,
        },
      ],
    },
  },
  2: {
    /* プレイヤー人数 */
    2: {
      /* プレイヤーID */
      1: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage2_2_1_1,
          imgId: 1,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage2_2_1_2,
          imgId: 2,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage2_2_1_3,
          imgId: 3,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage2_2_1_4,
          imgId: 4,
        },
        {
          id: 4,
          name: '画像5',
          imgPath: Stage2_2_1_5,
          imgId: 5,
        },
        {
          id: 5,
          name: '画像6',
          imgPath: Stage2_2_1_6,
          imgId: 6,
        },
      ],
      2: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage2_2_2_7,
          imgId: 7,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage2_2_2_8,
          imgId: 8,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage2_2_2_9,
          imgId: 9,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage2_2_2_10,
          imgId: 10,
        },
        {
          id: 4,
          name: '画像5',
          imgPath: Stage2_2_2_11,
          imgId: 11,
        },
        {
          id: 5,
          name: '画像6',
          imgPath: Stage2_2_2_12,
          imgId: 12,
        },
      ],
      3: [],
    },
    3: {
      /* プレイヤーID */
      1: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage2_3_1_1,
          imgId: 1,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage2_3_1_2,
          imgId: 2,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage2_3_1_3,
          imgId: 3,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage2_3_1_4,
          imgId: 4,
        },
      ],
      2: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage2_3_2_5,
          imgId: 5,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage2_3_2_6,
          imgId: 6,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage2_3_2_7,
          imgId: 7,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage2_3_2_8,
          imgId: 8,
        },
      ],
      3: [
        {
          id: 0,
          name: '画像1',
          imgPath: Stage2_3_3_9,
          imgId: 9,
        },
        {
          id: 1,
          name: '画像2',
          imgPath: Stage2_3_3_10,
          imgId: 10,
        },
        {
          id: 2,
          name: '画像3',
          imgPath: Stage2_3_3_11,
          imgId: 11,
        },
        {
          id: 3,
          name: '画像4',
          imgPath: Stage2_3_3_12,
          imgId: 12,
        },
      ],
    },
  },
};
