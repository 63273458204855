import { allIds, checker, placeholder, question } from '../data/data';
import { Comm1ContextType } from './Comm1Context';

export const calcUnchoosedId = (id: string, comm1?: Comm1ContextType) => {
  return allIds[comm1?.playerNum ?? 2]
    .split('')
    .filter(c => id.indexOf(c) === -1)
    .join('');
};

const defaultWrong = [
  [false, false, false, false],
  [false, false, false, false],
  [false, false, false, false],
];
export const calcWrong = (
  id1: string,
  id2: string,
  id3: string,
  stageId: 1 | 2 | null,
  comm1?: Comm1ContextType
): boolean[][] => {
  const playerNum = comm1?.playerNum;
  if (!playerNum) {
    return defaultWrong;
  }
  if (!stageId) {
    return defaultWrong;
  }
  const idValue1 = id1.split('').map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));
  const idValue2 = id2.split('').map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));
  if (playerNum === 2) {
    const wrong = idValue1.map(
      (e, i) =>
        checker[stageId][playerNum][
          question[stageId][playerNum][1][e]?.imgId
        ] !==
          checker[stageId][playerNum][
            question[stageId][playerNum][2][idValue2[i]]?.imgId
          ] ||
        checker[stageId][playerNum][
          question[stageId][playerNum][1][e]?.imgId
        ] === -1
    );
    return [wrong, wrong, defaultWrong[2]];
  } else {
    const idValue3 = id3
      .split('')
      .map(e => e.charCodeAt(0) - 'A'.charCodeAt(0));

    const idValues = {
      1: idValue1,
      2: idValue2,
      3: idValue3,
    };

    return [1, 2, 3].map(e => {
      const playerId = e as 1 | 2 | 3;
      const nextPlayerId = (((e + 1) % 3) + 1) as 1 | 2 | 3;
      const prevPlayerId = ((e % 3) + 1) as 1 | 2 | 3;
      return placeholder[playerNum]
        .split('')
        .map(
          (e, i) =>
            checker[stageId][playerNum][
              question[stageId][playerNum][playerId][idValues[playerId][i]]
                ?.imgId
            ] === -1 ||
            (checker[stageId][playerNum][
              question[stageId][playerNum][playerId][idValues[playerId][i]]
                ?.imgId
            ] !==
              checker[stageId][playerNum][
                question[stageId][playerNum][nextPlayerId][
                  idValues[nextPlayerId][i]
                ]?.imgId
              ] &&
              checker[stageId][playerNum][
                question[stageId][playerNum][playerId][idValues[playerId][i]]
                  ?.imgId
              ] !==
                checker[stageId][playerNum][
                  question[stageId][playerNum][prevPlayerId][
                    idValues[prevPlayerId][i]
                  ]?.imgId
                ])
        );
    });
  }
};
