import { CommonBG, Spinner } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

type LoadingProps = Record<string, never>;
const Loading: React.FC<LoadingProps> = () => {
  return (
    <CommonBG>
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    </CommonBG>
  );
};

export default Loading;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
