import { CommContext } from '../../../../hoc/CommContext';
import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import Comm2Note from './Comm2Note';
import Comm2Table from './Comm2Table';
import {
  CommonBG,
  FinishButton,
  ScoreText,
  Button as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Stage2Props {}

const Comm2Game: React.FC<Stage2Props> = () => {
  const duration = 15 * 60;
  const maxSpeakCount = 15;
  const firstPhase = 5 * 60;

  const { pathname } = useLocation();
  const [finished, setFinished] = React.useState(false);
  const { trainingId } = useParams<'trainingId'>();
  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = React.useState<number | null>(null);

  const onSetRemainingTime = (time: number) => {
    setRemainingTime(time);
    if (remainingTime != null) {
      if (time < duration - firstPhase && phase == 0) {
        setPhase(1);
      }
    }
  };

  React.useEffect(() => {
    setFinished(false);
  }, [pathname]);

  const ctx = React.useContext(CommContext);
  const [speakCount, setSpeakCount] = React.useState(0);

  const [phase, setPhase] = React.useState(0);

  return (
    <CommonBG>
      <MainWrapper>
        <TimeGauge
          timerKey={trainingId + '-comm2game'}
          duration={duration}
          stageName={InstructionText[2].title}
          logo={InstructionLogo}
          color={InstructionColor}
          onSkip={() => {
            navigate(`/${trainingId}/comm2/answer`);
          }}
          onFinished={() => {
            setFinished(true);
          }}
          onRefreshDuration={(time: number) => {
            onSetRemainingTime(time);
          }}
          onBack={() => {
            navigate(`/${trainingId}/stages`);
          }}
        />

        <FixedContent
          style={{
            animationName: phase == 0 ? 'showAnim' : 'hideAnim',
          }}
        >
          <p>
            会社の忘年会の会場を、A〜Dの中から全員で決めましょう。最初の5分間は、情報を読んで自分の意見をまとめましょう。
          </p>
        </FixedContent>
        {phase == 1 && (
          <FixedContent
            style={{
              animationName: phase == 1 ? 'showAnim' : 'hideAnim',
            }}
          >
            {maxSpeakCount - speakCount > 0 && (
              <>
                <p>
                  会社の忘年会の会場を、A～Dの中から全員で話し合って決めましょう。
                </p>
                <Left>
                  <Left1
                    color={
                      maxSpeakCount - speakCount <= 0 ? '#FF5414' : '#343a40'
                    }
                  />
                  <ScoreText
                    value={maxSpeakCount - speakCount}
                    color={
                      maxSpeakCount - speakCount <= 0 ? '#FF5414' : '#343A40'
                    }
                    size={42}
                  />
                  <Left2
                    color={
                      maxSpeakCount - speakCount <= 0 ? '#FF5414' : '#343a40'
                    }
                  />
                </Left>
                <Button
                  color='positive'
                  size='medium'
                  disabled={maxSpeakCount - speakCount <= 0}
                  onClick={() => {
                    console.log('reduce count');
                    setSpeakCount(prev => Math.min(maxSpeakCount, prev + 1));
                  }}
                >
                  回数を減らす
                </Button>
              </>
            )}
            {maxSpeakCount - speakCount === 0 && (
              <p>案内があるまで、しばらくお待ちください。</p>
            )}
          </FixedContent>
        )}

        <Content>
          <About>
            <h2>忘年会について</h2>
            <ul>
              <li>会社の、普段から一緒に働いているチームの忘年会</li>
              <li>チームの人数は18人で、基本的には全員出席</li>
              <li>
                感染症などの影響で忘年会そのものが開催しにくい、といった状態ではないとする
              </li>
            </ul>
          </About>
          <h2>会場の候補とその情報</h2>
          <Comm2Table />
          <Comm2Note />
        </Content>
        <FinishButton
          finished={remainingTime == 0}
          color={InstructionColor}
          click={() => {
            navigate(`/${trainingId}/comm2/answer`);
          }}
        >
          話し合って出た結論を入力しましょう！
        </FinishButton>
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;

  h2 {
    font-size: 2.4rem;
  }

  table {
    margin: 20px 0;
  }

  @keyframes showAnim {
    from {
      transform: translateY(-80px);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes hideAnim {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-80px);
    }
  }
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})``;

const Left = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: 20px;

  ul {
    margin: 0 6px;
    margin-bottom: -6px;
  }
`;

const FixedContent = styled.div`
  transform: translateY(0);
  animation-duration: 1.4s;

  z-index: 2;
  width: calc(100vw - 40px);
  background: white;
  margin: 0 auto;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  padding-top: 14px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);

  > p {
    font-size: 1.6rem;
    padding: 12px 20px;
    font-size: 2rem;
    font-weight: bold;
    white-space: nowrap;
    flex: 1;
  }

  @media screen and (max-width: 980px) {
    > p {
      font-size: 1.6rem;
    }
  }

  @media screen and (max-width: 860px) {
    > p {
      font-size: 1.2rem;
      white-space: pre-wrap;
      padding-left: 0;
    }
  }

  button {
    margin-right: 10px;
    white-space: nowrap;
  }
`;
const Content = styled.div`
  margin: 50px auto;
  padding: 0 30px;

  p {
    font-size: 1.6rem;
    background: rgba(206, 212, 218, 0.25);
    padding: 12px 20px;
  }
`;

const About = styled.div`
  margin-top: 60px;
  background: white;
  border: 5px solid #7a4aff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  h2 {
    padding-bottom: 2px;
    margin: 0 -5px;
    margin-top: -5px;
    background: #7a4aff;
    color: white;
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
  }

  ul {
    padding: 10px 20px;
    list-style-position: inside;
  }

  li {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  margin-bottom: 20px;
`;

const Left1 = (props: any) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='117.03'
      height='28.26'
      viewBox='0 0 117.03 28.26'
    >
      <path
        d='M-90.9-17.67a9.4,9.4,0,0,1-1.8-.3l1.77-3.6h-4.29l1.92-3.78h-6.72l-.9,1.74a4.948,4.948,0,0,1-.72-1.59h-16.29v5.58h6.39a6.756,6.756,0,0,1-1.26.9l-.15-.36h-5.97l.57,1.41h-.66v7.35a3.6,3.6,0,0,0,.6-.09v5.43h4.32c-.54.81-1.83.75-4.92.75V2.7c9.84,0,12.57-2.73,13.23-7.68h1.74v2.7c0,3.03,1.38,4.86,4.68,4.86h4.56a6.746,6.746,0,0,0,3.75-.75V-4.86a5.555,5.555,0,0,1-2.67.6h-2.01c-.36,0-.45-.21-.45-.51v-.21h4.53v-5.37c.21.03.42.09.6.12Zm-2.4,6.93h-2.88v-.66h.93C-94.62-11.16-93.96-10.92-93.3-10.74Zm-9.96-5.34h-2.91a9.59,9.59,0,0,0,1.35-1.65A16.139,16.139,0,0,0-103.26-16.08Zm-.78,5.52h-1.56v-.99h1.56Zm-9.66-.18h-3.21a20.882,20.882,0,0,0,2.07-.66h1.14ZM-62.04-21.9v-3.36H-87.93v3.36Zm.96,6.87v-6.42H-89.01v6.42Zm-.96,3.27v-2.82H-87.93v2.82Zm0,3.39v-2.91H-87.93v2.91Zm.72,10.68V-7.83H-88.59V2.31Zm-9.69-4.77h-7.8v-.93h7.8ZM-30.99,2.7V-4.35c-.36,0-.72-.03-1.05-.03a9.679,9.679,0,0,0,.45-1.02h-3.99A1.317,1.317,0,0,1-36-5.88h5.01v-6.45h-5.85v-.51h5.13v-4.8h-5.13v-.51h5.85V-24.3h-.87l-.12-1.11h-4.53l.15,1.11h-.87v-1.11h-8.22v1.11h-1.53v-.9H-59.01v6.48h1.2c-.12,1.23-.51,1.92-1.17,2.1v9.39a5.833,5.833,0,0,0,1.26-.51l.78,2.52a7.153,7.153,0,0,1-1.71.39V2.85C-54.15,2.4-51,.36-49.17-3V2.7A28.282,28.282,0,0,0-38.73.96,21.247,21.247,0,0,0-30.99,2.7Zm-14.1-15.03h-2.04l.03-.03V-18h-4.38l.09-.72h4.23v.57h2.04v.51h-1.5v4.8h1.5v.24C-45.12-12.48-45.09-12.42-45.09-12.33Zm1.17,7.77a23.67,23.67,0,0,1-4.68.39,17.519,17.519,0,0,0,1.29-5.22v3.51h2.94C-44.22-5.43-44.1-4.98-43.92-4.56Zm-8.55-7.59a12.8,12.8,0,0,1-.45,2.58l-.36-1.29h-.87a13.362,13.362,0,0,0,.78-1.29ZM-1.98-8.91V-14.4c0-5.1-1.77-9.93-8.31-9.93-1.74,0-3.6.57-7.02,3.18l-2.88,2.19v-5.43h-8.13V-8.04h8.13l5.25-4.53c2.25-1.92,2.97-2.46,3.72-2.46.81,0,1.05.66,1.05,1.32v4.35c0,2.37-.84,3.03-3.24,3.03H-25.14V1.95h11.67C-6.42,1.95-1.98-.84-1.98-8.91Z'
        transform='translate(119.01 25.41)'
        fill={props.color}
      />
    </svg>
  );
};

const Left2 = (props: any) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27.42'
      height='26.79'
      viewBox='0 0 27.42 26.79'
    >
      <path
        d='M28.77,2.1V-24.69H1.35V2.1ZM21.75-4.2H8.37V-18.06H21.75Zm-1.02-.99V-17.04H9.36V-5.19ZM15.96-9.84h-1.8v-2.49h1.8Z'
        transform='translate(-1.35 24.69)'
        fill={props.color}
      />
    </svg>
  );
};

export default Comm2Game;
