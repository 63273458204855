import * as React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface Stage2FieldProps {}

export type pair = {
  id: number;
  name: string;
};

export type tuple = {
  id: number;
  title: string;
  explanation: string;
};

export type Item = {
  questionCount: number;
  title: string;
  features: pair[];
  featuresRev: pair[];
  ideas: tuple[];
};

const Stage2Field: React.FC<Stage2FieldProps> = props => {
  const location = useLocation();
  const [resultSet] = React.useState<any>(location.state);

  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1); // 現在のステップ(1~3)
  const [tempFeatures, setTempFeatures] = useState<pair[]>([]);
  const [tempFeaturesRev, setTempFeaturesRev] = useState<pair[]>([]);
  const [tempStep3Ans, setTempStep3Ans] = useState<tuple[]>([
    { id: 0, title: '', explanation: '' },
  ]);

  const goTo = (url: string, params: any) => {
    window.setTimeout(() => {
      navigate(url, {
        state: params,
      });
    }, 0);
  };

  const sendFeature = (str: string) => {
    if (str === '') return;
    const add: pair = { id: tempFeatures.length, name: str };
    const tmp = [...tempFeatures, add];
    setTempFeatures(tmp);
  };

  const sendFeatureRev = (str: string) => {
    if (str === '') return;
    if (tempFeatures.length <= tempFeaturesRev.length) return;
    const add: pair = { id: tempFeaturesRev.length, name: str };
    const tmp = [...tempFeaturesRev, add];
    setTempFeaturesRev(tmp);
  };

  const deleteFreature = (i: number) => {
    const tmp = [...tempFeatures];
    tmp.splice(i, 1);
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].id = i;
    }
    setTempFeatures(tmp);
    if (i >= tempFeaturesRev.length) return;
    const tmp2 = [...tempFeaturesRev];
    tmp2.splice(i, 1);
    for (let i = 0; i < tmp2.length; i++) {
      tmp2[i].id = i;
    }
    setTempFeaturesRev(tmp2);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };

  return <Wrap></Wrap>;
};

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const DmyBtn = styled.div`
  width: 170px;
`;

const Header = styled.div`
  margin-top: 10px;
  padding: 8px 20px;
  width: calc(100% - 40px);
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

  ul {
    flex: 1;
    display: flex;
    margin: 0 20px;
    justify-content: center;

    li {
      list-style: none;
    }
  }

  button:first-child {
    svg {
      margin-right: 10px;
      margin-left: -4px;
    }
  }

  button:last-child {
    svg {
      margin-right: -4px;
      margin-left: 10px;
    }
  }
`;

interface singleStepProps {
  selected: boolean;
}

const SingleStep = styled.div`
  padding: 0 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: -8px;
  padding-bottom: 4px;
  p {
    font-size: 2rem;
  }
  span {
    font-size: 1.3rem;
  }

  ${(p: singleStepProps) => {
    return p.selected
      ? `
        color: #4F78FC;
        border-bottom: 4px solid #4F78FC;
    `
      : `
        color: #868E96;
        border-bottom: 4px solid white;
    `;
  }}
`;

const Text1 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const Text2 = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 400;
`;

const Text1a = styled.a`
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const View = styled.div`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
`;

const MyWrapper = styled.div`
  width: 1000px;
  height: calc(100vh - 3rem);
  display: flex;
  overflow: hidden;
`;

export default Stage2Field;
