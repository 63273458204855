import Icon_Player2_1 from '../../static/png/player_2_1.png';
import Icon_Player2_2 from '../../static/png/player_2_2.png';
import Icon_Player3_1 from '../../static/png/player_3_1.png';
import Icon_Player3_2 from '../../static/png/player_3_2.png';
import Icon_Player3_3 from '../../static/png/player_3_3.png';

interface IconProps {
  maxPlayers: number;
  player: number;
  width: number;
  height: number;
}

const UserIcon: React.FC<IconProps> = (props: IconProps) => {
  const Icons = [
    [Icon_Player2_1, Icon_Player2_2],
    [Icon_Player3_1, Icon_Player3_2, Icon_Player3_3],
  ];

  if (props.maxPlayers != 2 && props.maxPlayers != 3) {
    return null;
  }

  if (Icons[props.maxPlayers - 2].length <= props.player) {
    return null;
  }

  return (
    <img
      src={Icons[props.maxPlayers - 2][props.player]}
      width={props.width}
      height={props.height}
    />
  );
};

export default UserIcon;
